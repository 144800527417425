/*-------Tooptip &popover------*/

.bd-example {
	position: relative;
	padding: 1rem;
	margin: 1rem -1rem;
	border-width: .2rem 0 0;
}

.bd-example-tooltip-static .tooltip {
	position: relative;
	display: inline-block;
	margin: 10px 20px;
	opacity: 1;
}

.tooltip {
	&.bs-tether-element-attached-bottom,
	&.tooltip-top {
		padding: 5px 0;
		margin-top: -3px;
	}
	position: absolute;
	z-index: 1070;
	display: block;
	font-style: normal;
	font-weight: 400;
	letter-spacing: normal;
	line-break: auto;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	white-space: normal;
	word-break: normal;
	word-spacing: normal;
	font-size: .875rem;
	word-wrap: break-word;
	opacity: 0;
}

.tooltip-inner {
	max-width: 200px;
	padding: 3px 8px;
	color: $white;
	text-align: center;
	background-color: $black;
	border-radius: .25rem;
}

.tooltip {
	&.bs-tether-element-attached-left,
	&.tooltip-end {
		padding: 0 5px;
		margin-left: 3px;
	}
	&.bs-tether-element-attached-bottom .tooltip-inner::before,
	&.tooltip-top .tooltip-inner::before {
		bottom: 0;
		left: 50%;
		margin-left: -5px;
		content: "";
		border-width: 5px 5px 0;
		border-top-color: $black;
	}
	&.bs-tether-element-attached-left .tooltip-inner::before,
	&.tooltip-end .tooltip-inner::before {
		top: 50%;
		left: 0;
		margin-top: -5px;
		content: "";
		border-width: 5px 5px 5px 0;
		border-right-color: $black;
	}
	&.bs-tether-element-attached-top,
	&.tooltip-bottom {
		padding: 5px 0;
		margin-top: 3px;
	}
	&.bs-tether-element-attached-top .tooltip-inner::before,
	&.tooltip-bottom .tooltip-inner::before {
		top: 0;
		left: 50%;
		margin-left: -5px;
		content: "";
		border-width: 0 5px 5px;
		border-bottom-color: $black;
	}
	&.bs-tether-element-attached-right,
	&.tooltip-start {
		padding: 0 5px;
		margin-left: -3px;
	}
	&.bs-tether-element-attached-right .tooltip-inner::before,
	&.tooltip-start .tooltip-inner::before {
		top: 50%;
		right: 0;
		margin-top: -5px;
		content: "";
		border-width: 5px 0 5px 5px;
		border-left-color: $black;
	}
}

.tooltip-inner::before {
	position: absolute;
	width: 0;
	height: 0;
	border-color: transparent;
	border-style: solid;
}


/*-------Tooptip &popover------*/


/*---Alerts-colors---*/

.pop-primary {
	color: #4d9dff;
}

.popsecondary {
	color: #628dea;
}

.popinfo {
	color: #88c8f7;
}

.popsuccess {
	color: #bfff80;
}

.popdanger {
	color: #ea6262;
}

.popwarning {
	color: #f7db6e;
}


/*---Alerts-colors---*/

.tooltip {
	position: absolute;
	z-index: 1029;
	display: block;
	margin: 0;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: 0.875rem;
	word-wrap: break-word;
	opacity: 0;
	&.show {
		opacity: 0.9;
	}
	.popover-arrow {
		position: absolute;
		display: block;
		width: 0.8rem;
		height: 0.4rem;
		&::before {
			position: absolute;
			content: "";
			border-color: transparent;
			border-style: solid;
		}
	}
}

.bs-tooltip-top,
.bs-tooltip-auto[data-popper-placement^="top"] {
	padding: 0.4rem 0;
}

.bs-tooltip-top .popover-arrow,
.bs-tooltip-auto[data-popper-placement^="top"] .popover-arrow {
	bottom: 0;
}

.bs-tooltip-top .popover-arrow::before,
.bs-tooltip-auto[data-popper-placement^="top"] .popover-arrow::before {
	top: 0;
	border-width: 0.4rem 0.4rem 0;
	border-top-color: $black;
}

.bs-tooltip-end,
.bs-tooltip-auto[data-popper-placement^="right"] {
	padding: 0 0.4rem;
}

.bs-tooltip-end .popover-arrow,
.bs-tooltip-auto[data-popper-placement^="right"] .popover-arrow {
	left: 0;
	width: 0.4rem;
	height: 0.8rem;
}

.bs-tooltip-end .popover-arrow::before,
.bs-tooltip-auto[data-popper-placement^="right"] .popover-arrow::before {
	right: 0;
	border-width: 0.4rem 0.4rem 0.4rem 0;
	border-right-color: $black;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[data-popper-placement^="bottom"] {
	padding: 0.4rem 0;
}

.bs-tooltip-bottom .popover-arrow,
.bs-tooltip-auto[data-popper-placement^="bottom"] .popover-arrow {
	top: 0;
}

.bs-tooltip-bottom .popover-arrow::before,
.bs-tooltip-auto[data-popper-placement^="bottom"] .popover-arrow::before {
	bottom: 0;
	border-width: 0 0.4rem 0.4rem;
	border-bottom-color: $black;
}

.bs-tooltip-start,
.bs-tooltip-auto[data-popper-placement^="left"] {
	padding: 0 0.4rem;
}

.bs-tooltip-start .popover-arrow,
.bs-tooltip-auto[data-popper-placement^="left"] .popover-arrow {
	right: 0;
	width: 0.4rem;
	height: 0.8rem;
}

.bs-tooltip-start .popover-arrow::before,
.bs-tooltip-auto[data-popper-placement^="left"] .popover-arrow::before {
	left: 0;
	border-width: 0.4rem 0 0.4rem 0.4rem;
	border-left-color: $black;
}

.tooltip-inner {
	max-width: 200px;
	padding: 0.25rem 0.5rem;
	color: $white;
	text-align: center;
	background-color: $black;
	border-radius: 3px;
}

.popover {
	top: 0;
	left: 0;
	z-index: 1060;
	display: block;
	max-width: 276px;
	font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
	font-style: normal;
	font-weight: 400;
	line-height: 1.5;
	text-align: left;
	text-align: start;
	text-decoration: none;
	text-shadow: none;
	text-transform: none;
	letter-spacing: normal;
	word-break: normal;
	word-spacing: normal;
	white-space: normal;
	line-break: auto;
	font-size: 0.875rem;
	word-wrap: break-word;
	background-color: $white;
	background-clip: padding-box;
	border: 1px solid #dee3eb;
	border-radius: 3px;
	.popover-arrow {
		position: absolute;
		display: block;
		width: 0.5rem;
		height: 0.5rem;
		margin: 0 3px;
		&::before,
		&::after {
			position: absolute;
			display: block;
			content: "";
			border-color: transparent;
			border-style: solid;
		}
	}
}

.bs-popover-top,
.bs-popover-auto[data-popper-placement^="top"] {
	margin-bottom: 0.5rem;
}

.bs-popover-top .popover-arrow,
.bs-popover-auto[data-popper-placement^="top"] .popover-arrow {
	bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] .popover-arrow::before,
.bs-popover-top .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] .popover-arrow::after {
	border-width: 0.5rem 0.25rem 0;
}

.bs-popover-top .popover-arrow::before,
.bs-popover-auto[data-popper-placement^="top"] .arrow::before {
	bottom: 0;
	border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top .popover-arrow::after,
.bs-popover-auto[data-popper-placement^="top"] .arrow::after {
	bottom: 1px;
	border-top-color: $white;
}

.bs-popover-end,
.bs-popover-auto[data-popper-placement^="right"] {
	margin-left: 0.5rem;
}

.bs-popover-end .arrow,
.bs-popover-auto[data-popper-placement^="right"] .arrow {
	left: calc((0.5rem + 1px) * -1);
	width: 0.5rem;
	height: 0.5rem;
	margin: 3px 0;
}

.bs-popover-end .arrow::before,
.bs-popover-auto[data-popper-placement^="right"] .arrow::before,
.bs-popover-end .arrow::after,
.bs-popover-auto[data-popper-placement^="right"] .arrow::after {
	border-width: 0.25rem 0.5rem 0.25rem 0;
}

.bs-popover-end .arrow::before,
.bs-popover-auto[data-popper-placement^="right"] .arrow::before {
	left: 0;
	border-right-color: #dee3eb;
}

.bs-popover-end .arrow::after,
.bs-popover-auto[data-popper-placement^="right"] .arrow::after {
	left: 1px;
	border-right-color: $white;
}

.bs-popover-bottom,
.bs-popover-auto[data-popper-placement^="bottom"] {
	margin-top: 0.5rem;
}

.bs-popover-bottom .arrow,
.bs-popover-auto[data-popper-placement^="bottom"] .arrow {
	top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] .arrow::after {
	border-width: 0 0.25rem 0.5rem 0.25rem;
}

.bs-popover-bottom .arrow::before,
.bs-popover-auto[data-popper-placement^="bottom"] .arrow::before {
	top: 0;
	border-bottom-color: #dee3eb;
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[data-popper-placement^="bottom"] .arrow::after {
	top: 1px;
	border-bottom-color: $white;
}

.bs-popover-bottom .popover-header::before,
.bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
	position: absolute;
	top: 0;
	left: 50%;
	display: block;
	width: 0.5rem;
	margin-left: -0.25rem;
	content: "";
	border-bottom: 1px solid $white;
}

.bs-popover-start,
.bs-popover-auto[data-popper-placement^="left"] {
	margin-right: 0.5rem;
}

.bs-popover-start .arrow,
.bs-popover-auto[data-popper-placement^="left"] .arrow {
	right: calc((0.5rem + 1px) * -1);
	width: 0.5rem;
	height: 0.5rem;
	margin: 3px 0;
}

.bs-popover-start .arrow::before,
.bs-popover-auto[data-popper-placement^="left"] .arrow::before,
.bs-popover-start .arrow::after,
.bs-popover-auto[data-popper-placement^="left"] .arrow::after {
	border-width: 0.25rem 0 0.25rem 0.5rem;
}

.bs-popover-start .arrow::before,
.bs-popover-auto[data-popper-placement^="left"] .arrow::before {
	right: 0;
	border-left-color: #dee3eb;
}

.bs-popover-start .arrow::after,
.bs-popover-auto[data-popper-placement^="left"] .arrow::after {
	right: 1px;
	border-left-color: $white;
}

.popover-header {
	padding: 0.5rem 0.75rem;
	margin-bottom: 0;
	font-size: 0.9375rem;
	color: inherit;
	background-color: $white;
	border-bottom: 1px solid #ebebeb;
	border-top-left-radius: calc(3px - 1px);
	border-top-right-radius: calc(3px - 1px);
	&:empty {
		display: none;
	}
}

.popover-body {
	padding: 0.75rem 1rem;
	color: #5f6877;
}


/*------Tooltip & Popover-------*/

.popover {
	-webkit-filter: drop-shadow(0 1px 3px $black-1);
	filter: drop-shadow(0 1px 3px $black-1);
	&.bs-popover-top,
	&.bs-popover-auto[data-popper-placement^="top"] {
		margin-bottom: 0.625rem;
	}
	.arrow {
		margin-left: calc(.25rem + -5px);
	}
}

.popover-static-demo .popover {
	.arrow {
		margin-left: calc(.25rem + 122px);
	}
	position: relative;
}

#popup {
	position: absolute;
	width: auto;
	height: 30px;
	background: #fe6b1f;
	display: none;
	color: white;
	border-radius: 5px;
}
.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after, .bs-popover-bottom>.popover-arrow::after {
    border-bottom-color: $white !important;
}
.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after, .bs-popover-start>.popover-arrow::after {
    border-left-color: $white !important;
}
.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after, .bs-popover-end>.popover-arrow::after {
    border-right-color: $white !important;
}