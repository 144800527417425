.img-gallery {
	margin-right: -.25rem;
	margin-left: -.25rem;
	margin-bottom: -.5rem;

	> {

		.col,
		[class*="col-"] {
			padding-left: .25rem;
			padding-right: .25rem;
			padding-bottom: .5rem;
		}
	}
}


/*----Image Check----*/

.imagecheck {
	margin: 0;
	position: relative;
	cursor: pointer;
}

.imagecheck-input {
	position: absolute;
	z-index: -1;
	opacity: 0;
}

.imagecheck-figure {
	border: 1px solid $border;
	border-radius: 3px;
	margin: 0;
	position: relative;
}

.imagecheck-input:checked~.imagecheck-figure {
	border-color: rgba(0, 40, 100, 0.24);
}

.imagecheck-figure:before {
	content: '';
	position: absolute;
	top: .25rem;
	left: .25rem;
	display: block;
	width: 1rem;
	height: 1rem;
	pointer-events: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	z-index: 1;
	border-radius: 3px;
	opacity: 0;
	transition: .3s opacity;
}

.imagecheck-input:checked~.imagecheck-figure:before {
	opacity: 1;
}

.imagecheck-image {
	max-width: 100%;
	opacity: .64;
	transition: .3s opacity;

	&:first-child {
		border-top-left-radius: 2px;
		border-top-right-radius: 2px;
	}

	&:last-child {
		border-bottom-left-radius: 2px;
		border-bottom-right-radius: 2px;
	}
}

.imagecheck:hover .imagecheck-image {
	opacity: 1;
}

.imagecheck-input {

	&:focus~.imagecheck-figure .imagecheck-image,
	&:checked~.imagecheck-figure .imagecheck-image {
		opacity: 1;
	}
}

.imagecheck-caption {
	text-align: center;
	padding: .25rem .25rem;
	color: #a7b4c9;
	font-size: 0.875rem;
	transition: .3s color;
}

.imagecheck:hover .imagecheck-caption {
	color: #3d4e67;
}

.imagecheck-input {

	&:focus~.imagecheck-figure .imagecheck-caption,
	&:checked~.imagecheck-figure .imagecheck-caption {
		color: #3d4e67;
	}
}


/*----Image Check----*/


/*-----Gallery-----*/

.demo-gallery {
	>ul {
		margin-bottom: 0;

		>li {
			float: left;
			margin-bottom: 15px;
			width: 200px;
			border: 0;

			a {
				border: 3px solid $white;
				border-radius: 3px;
				display: block;
				overflow: hidden;
				position: relative;
				float: left;

				>img {
					-webkit-transition: -webkit-transform 0.15s ease 0s;
					-moz-transition: -moz-transform 0.15s ease 0s;
					-o-transition: -o-transform 0.15s ease 0s;
					transition: transform 0.15s ease 0s;
					-webkit-transform: scale3d(1, 1, 1);
					transform: scale3d(1, 1, 1);
					height: 100%;
					width: 100%;
				}

				&:hover {
					>img {
						-webkit-transform: scale3d(1.1, 1.1, 1.1);
						transform: scale3d(1.1, 1.1, 1.1);
					}

					.demo-gallery-poster>img {
						opacity: 1;
					}
				}

				.demo-gallery-poster {
					background-color: $black-1;
					bottom: 0;
					left: 0;
					position: absolute;
					right: 0;
					top: 0;
					-webkit-transition: background-color 0.15s ease 0s;
					-o-transition: background-color 0.15s ease 0s;
					transition: background-color 0.15s ease 0s;

					>img {
						left: 50%;
						margin-left: -10px;
						margin-top: -10px;
						opacity: 0;
						position: absolute;
						top: 50%;
						-webkit-transition: opacity 0.3s ease 0s;
						-o-transition: opacity 0.3s ease 0s;
						transition: opacity 0.3s ease 0s;
					}
				}

				&:hover .demo-gallery-poster {
					background-color: $black-5;
				}
			}
		}
	}

	.justified-gallery>a {
		>img {
			-webkit-transition: -webkit-transform 0.15s ease 0s;
			-moz-transition: -moz-transform 0.15s ease 0s;
			-o-transition: -o-transform 0.15s ease 0s;
			transition: transform 0.15s ease 0s;
			-webkit-transform: scale3d(1, 1, 1);
			transform: scale3d(1, 1, 1);
			height: 100%;
			width: 100%;
		}

		&:hover {
			>img {
				-webkit-transform: scale3d(1.1, 1.1, 1.1);
				transform: scale3d(1.1, 1.1, 1.1);
			}

			.demo-gallery-poster>img {
				opacity: 1;
			}
		}

		.demo-gallery-poster {
			background-color: $black-1;
			bottom: 0;
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			-webkit-transition: background-color 0.15s ease 0s;
			-o-transition: background-color 0.15s ease 0s;
			transition: background-color 0.15s ease 0s;

			>img {
				left: 50%;
				margin-left: -10px;
				margin-top: -10px;
				opacity: 0;
				position: absolute;
				top: 50%;
				-webkit-transition: opacity 0.3s ease 0s;
				-o-transition: opacity 0.3s ease 0s;
				transition: opacity 0.3s ease 0s;
			}
		}

		&:hover .demo-gallery-poster {
			background-color: $black-5;
		}
	}

	.video .demo-gallery-poster img {
		height: 48px;
		margin-left: -24px;
		margin-top: -24px;
		opacity: 0.8;
		width: 48px;
	}

	&.dark>ul>li a {
		border: 3px solid #04070a;
	}
}

.home .demo-gallery {
	padding-bottom: 80px;
}

.carousel-inner>.item> {

	img,
	a>img {
		width: 100%;
	}
}

.gallery {
	overflow: hidden;
	position: relative;
	text-align: center;
	box-shadow: 1px 1px 2px #e6e6e6;
	cursor: default;

	.mask,
	.content {
		position: absolute;
		width: 100%;
		overflow: hidden;
		top: 0;
		left: 0;
	}

	img {
		display: block;
		position: relative;
	}

	.tools {
		text-transform: uppercase;
		color: $white;
		text-align: center;
		position: relative;
		font-size: 17px;
		padding: 3px;
		background: rgba(0, 0, 0, 0.35);
		margin: 43px 0 0 0;
	}
}

.mask.no-caption .tools {
	margin: 90px 0 0 0;
}

.gallery {
	.tools a {
		display: inline-block;
		color: $white;
		font-size: 18px;
		font-weight: 400;
		padding: 0 4px;
	}

	p {
		font-family: 'Lato', sans-serif;
		font-style: italic;
		font-size: 12px;
		position: relative;
		color: $white;
		padding: 10px 20px 20px;
		text-align: center;
	}

	a.info {
		display: inline-block;
		text-decoration: none;
		padding: 7px 14px;
		background: $black;
		color: $white;
		text-transform: uppercase;
		box-shadow: 0 0 1px $black;
	}
}

.gallery-first {
	img {
		transition: all 0.2s linear;
	}

	.mask {
		opacity: 0;
		background-color: $black-5;
		transition: all 0.4s ease-in-out;
	}

	.tools {
		transform: translateY(-100px);
		opacity: 0;
		transition: all 0.2s ease-in-out;
	}

	p {
		transform: translateY(100px);
		opacity: 0;
		transition: all 0.2s linear;
	}

	&:hover {
		img {
			transform: scale(1.1);
		}

		.mask {
			opacity: 1;
		}

		.tools {
			opacity: 1;
			transform: translateY(0px);
		}

		p {
			opacity: 1;
			transform: translateY(0px);
			transition-delay: 0.1s;
		}
	}
}

.form-group.has-feedback span {
	display: block !important;
}


/*-----Gallery-----*/

.img-avatar {
	height: 37px;
	border-radius: 2px;
	width: 37px;
}

.img-thumbnail {
	padding: 0.25rem;
	background-color: $white;
	border: 1px solid $border;
	border-radius: 3px;
	max-width: 100%;
	height: auto;
}

.img-flag {
	width: 25px;
	height: 12px;
	margin-top: -4px;
}

.demo-gallery>ul>li a>img {
	border-radius: 4px;
}