body.rtl {
    text-align: right;
}

@media (min-width: 1600px) {
    body.rtl.aside-opened .page {
        margin-right: 0;
        margin-left: 22rem;
    }
}

@supports (-webkit-touch-callout: none) {
    @media screen and (max-device-width: 991px) and (orientation: portrait) {
        body.rtl {

            .header-absolute.header-stick,
            &.active .header-absolute {
                left: 0;
                right: auto;
            }

            .header-main-banner,
            .header-absolute.header-stick.header-main-banner {
                left: auto;
                right: 0;
            }

            &.active .header-absolute.header-stick.header-main-banner {
                left: 0;
                right: auto;
            }
        }
    }

    @media screen and (max-device-width: 991px) and (orientation: landscape) {
        body.rtl {

            .header-absolute.header-stick,
            &.active .header-absolute {
                left: 0;
                right: auto;
            }

            .header-main-banner,
            .header-absolute.header-stick.header-main-banner {
                left: auto;
                right: 0;
            }

            &.active .header-absolute.header-stick.header-main-banner {
                left: 0;
                right: auto;
            }
        }
    }
}

/*---- RTL Styles -------*/
.rtl {

    .br-ts-7 {
        border-top-right-radius: 7px !important;
        border-top-left-radius: inherit !important;
    }

    .br-ts-10 {
        border-top-right-radius: 1rem !important;
        border-top-left-radius: 0 !important;
    }

    .br-bs-7 {
        border-bottom-right-radius: 7px !important;
        border-bottom-left-radius: 0 !important;
    }

    .br-bs-10 {
        border-bottom-right-radius: 1rem !important;
        border-bottom-left-radius: 0 !important;
    }

    .br-te-7 {
        border-top-left-radius: 7px !important;
        border-top-right-radius: inherit !important;
    }

    .br-te-10 {
        border-top-right-radius: inherit !important;
        border-top-left-radius: 1rem !important;
    }

    .br-be-7 {
        border-bottom-right-radius: inherit !important;
        border-bottom-left-radius: 7px !important;
    }

    .br-be-10 {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 1rem !important;
    }

    .br-ts-0 {
        border-top-right-radius: 0 !important;
        border-top-left-radius: 8px !important;
    }

    .br-bs-0 {
        border-bottom-right-radius: 0 !important;
        border-bottom-left-radius: 8px !important;
    }

    .br-te-0 {
        border-top-left-radius: 0 !important;
        border-top-right-radius: 8px !important;
    }

    .br-be-0 {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 8px !important;
    }

    .br-ts-2 {
        border-top-right-radius: 2px !important;
        border-top-left-radius: 0 !important;
    }

    .br-bs-2 {
        border-bottom-right-radius: 2px !important;
        border-bottom-left-radius: 0 !important;
    }

    .br-te-2 {
        border-top-left-radius: 2px !important;
        border-top-right-radius: 0 !important;
    }

    .br-be-2 {
        border-bottom-left-radius: 2px !important;
        border-bottom-right-radius: 0 !important;
    }

    .border-end {
        border-right: 0 !important;
        border-left: 1px solid $border  !important;
    }

    .border-start {
        border-right: 1px solid $border  !important;
        border-left: 0 !important;
    }

    .border-end-0 {
        border-left: 0 !important;
        border-right: 1px solid $border  !important;
    }

    .border-start-0 {
        border-right: 0 !important;
        border-left: 1px solid $border  !important;
    }

    .rounded-end {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 3px !important;
        border-bottom-left-radius: 3px !important;
    }

    .rounded-start {
        border-top-right-radius: 3px !important;
        border-bottom-right-radius: 3px !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    @media (min-width: 992px) {
        .br-be-md-0 {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 8px !important;
        }

        .br-te-md-0 {
            border-top-left-radius: 0 !important;
            border-top-right-radius: 8px !important;
        }

        .br-bs-md-0 {
            border-bottom-right-radius: 0 !important;
            border-bottom-left-radius: 8px !important;
        }

        .br-ts-md-0 {
            border-top-right-radius: 0 !important;
            border-top-left-radius: 8px !important;
        }

        .br-ts-md-2 {
            border-top-right-radius: 2px !important;
            border-top-left-radius: 0 !important;
        }

        .br-bs-md-2 {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 2px !important;
        }

        .br-te-md-2 {
            border-top-right-radius: 0 !important;
            border-top-left-radius: 2px !important;
        }

        .br-be-md-2 {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 2px !important;
        }
    }

    .float-end {
        float: left !important;
    }

    .float-start {
        float: right !important;
    }

    @media (min-width: 576px) {
        .float-sm-start {
            float: right !important;
        }

        .float-sm-end {
            float: left !important;
        }
    }

    @media (min-width: 768px) {
        .float-md-start {
            float: right !important;
        }

        .float-md-end {
            float: left !important;
        }
    }

    @media (min-width: 992px) {
        .float-lg-start {
            float: right !important;
        }

        .float-lg-end {
            float: left !important;
        }
    }

    @media (min-width: 1280px) {
        .float-xl-start {
            float: right !important;
        }

        .float-xl-end {
            float: left !important;
        }
    }


    .me-0 {
        margin-right: auto !important;
        margin-left: 0 !important;
    }

    .ms-0 {
        margin-left: auto !important;
        margin-right: 0 !important;
    }

    .me-1 {
        margin-right: 0 !important;
        margin-left: 0.25rem !important;
    }

    .ms-1 {
        margin-left: 0 !important;
        margin-right: 0.25rem !important;
    }

    .me-2 {
        margin-left: 0.5rem !important;
        margin-right: 0 !important;
    }

    .ms-2 {
        margin-right: 0.5rem !important;
        margin-left: 0 !important;
    }

    .me-3 {
        margin-left: 0.75rem !important;
        margin-right: 0 !important;
    }

    .ms-3 {
        margin-right: 0.75rem !important;
        margin-left: 0 !important;
    }

    .me-4 {
        margin-left: 1rem !important;
        margin-right: 0 !important;
    }

    .ms-4 {
        margin-right: 1rem !important;
        margin-left: 0 !important;
    }

    .me-5 {
        margin-left: 1.5rem !important;
        margin-right: 0 !important;
    }

    .ms-5 {
        margin-right: 1.5rem !important;
        margin-left: 0 !important;
    }

    .me-6 {
        margin-left: 2rem !important;
        margin-right: 0 !important;
    }

    .ms-6 {
        margin-right: 2rem !important;
        margin-left: 0 !important;
    }

    .me-7 {
        margin-left: 3rem !important;
        margin-right: 0 !important;
    }

    .ms-7 {
        margin-right: 3rem !important;
        margin-left: 0 !important;
    }

    .me-8 {
        margin-left: 4rem !important;
        margin-right: 0 !important;
    }

    .ms-8 {
        margin-right: 4rem !important;
        margin-left: 0 !important;
    }

    .me-9 {
        margin-left: 6rem !important;
        margin-right: 0 !important;
    }

    .ms-9 {
        margin-right: 6rem !important;
        margin-left: 0 !important;
    }

    .me-auto {
        margin-left: auto !important;
        margin-right: 0 !important;
    }

    .ms-auto {
        margin-right: auto !important;
        margin-left: 0 !important;
    }

    @media (min-width: 576px) {
        .me-sm-0 {
            margin-left: 0 !important;
            margin-right: auto !important;
        }

        .ms-sm-0 {
            margin-right: 0 !important;
            margin-left: auto !important;
        }

        .me-sm-1 {
            margin-left: 0.25rem !important;
            margin-right: 0 !important;
        }

        .ms-sm-1 {
            margin-right: 0.25rem !important;
            margin-left: 0 !important;
        }

        .me-sm-2 {
            margin-left: 0.5rem !important;
            margin-right: 0 !important;
        }

        .ms-sm-2 {
            margin-right: 0.5rem !important;
            margin-left: 0 !important;
        }

        .me-sm-3 {
            margin-left: 0.75rem !important;
            margin-right: 0 !important;
        }

        .ms-sm-3 {
            margin-right: 0.75rem !important;
            margin-left: 0 !important;
        }

        .me-sm-4 {
            margin-left: 1rem !important;
            margin-right: 0 !important;
        }

        .ms-sm-4 {
            margin-right: 1rem !important;
            margin-left: 0 !important;
        }

        .me-sm-5 {
            margin-left: 1.5rem !important;
            margin-right: 0 !important;
        }

        .ms-sm-5 {
            margin-right: 1.5rem !important;
            margin-left: 0 !important;
        }

        .me-sm-6 {
            margin-left: 2rem !important;
            margin-right: 0 !important;
        }

        .ms-sm-6 {
            margin-right: 2rem !important;
            margin-left: 0 !important;
        }

        .me-sm-7 {
            margin-left: 3rem !important;
            margin-right: 0 !important;
        }

        .ms-sm-7 {
            margin-right: 3rem !important;
            margin-left: 0 !important;
        }

        .me-sm-8 {
            margin-left: 4rem !important;
            margin-right: 0 !important;
        }

        .ms-sm-8 {
            margin-right: 4rem !important;
            margin-left: 0 !important;
        }

        .me-sm-9 {
            margin-left: 6rem !important;
            margin-right: 0 !important;
        }

        .ms-sm-9 {
            margin-right: 6rem !important;
            margin-left: 0 !important;
        }

        .me-sm-auto {
            margin-left: auto !important;
            margin-right: 0 !important;
        }

        .ms-sm-auto {
            margin-right: auto !important;
            margin-left: 0 !important;
        }
    }

    @media (min-width: 768px) {
        .me-md-0 {
            margin-left: 0 !important;
            margin-right: auto !important;
        }

        .ms-md-0 {
            margin-right: 0 !important;
            margin-left: auto !important;
        }

        .me-md-1 {
            margin-left: 0.25rem !important;
            margin-right: 0 !important;
        }

        .ms-md-1 {
            margin-right: 0.25rem !important;
            margin-left: auto !important;
        }

        .me-md-2 {
            margin-left: 0.5rem !important;
            margin-right: 0 !important;
        }

        .ms-md-2 {
            margin-right: 0.5rem !important;
            margin-left: 0 !important;
        }

        .me-md-3 {
            margin-left: 0.75rem !important;
            margin-right: 0 !important;
        }

        .ms-md-3 {
            margin-right: 0.75rem !important;
            margin-left: 0 !important;
        }

        .me-md-4 {
            margin-left: 1rem !important;
            margin-right: 0 !important;
        }

        .ms-md-4 {
            margin-right: 1rem !important;
            margin-left: 0 !important;
        }

        .me-md-5 {
            margin-left: 1.5rem !important;
            margin-right: 0 !important;
        }

        .ms-md-5 {
            margin-right: 1.5rem !important;
            margin-left: 0 !important;
        }

        .me-md-6 {
            margin-left: 2rem !important;
            margin-right: 0 !important;
        }

        .ms-md-6 {
            margin-right: 2rem !important;
            margin-left: 0 !important;
        }

        .me-md-7 {
            margin-left: 3rem !important;
            margin-right: 0 !important;
        }

        .ms-md-7 {
            margin-right: 3rem !important;
            margin-left: 0 !important;
        }

        .me-md-8 {
            margin-left: 4rem !important;
            margin-right: 0 !important;
        }

        .ms-md-8 {
            margin-right: 4rem !important;
            margin-left: 0 !important;
        }

        .me-md-9 {
            margin-left: 6rem !important;
            margin-right: 0 !important;
        }

        .ms-md-9 {
            margin-right: 6rem !important;
            margin-left: 0 !important;
        }

        .me-md-auto {
            margin-left: auto !important;
            margin-right: 0 !important;
        }

        .ms-md-auto {
            margin-right: auto !important;
            margin-left: 0 !important;
        }
    }

    @media (min-width: 992px) {
        .me-lg-0 {
            margin-left: 0 !important;
            margin-right: auto !important;
        }

        .ms-lg-0 {
            margin-right: 0 !important;
            margin-left: auto !important;
        }

        .me-lg-1 {
            margin-left: 0.25rem !important;
            margin-right: 0 !important;
        }

        .ms-lg-1 {
            margin-right: 0.25rem !important;
            margin-left: 0 !important;
        }

        .me-lg-2 {
            margin-left: 0.5rem !important;
            margin-right: 0 !important;
        }

        .ms-lg-2 {
            margin-right: 0.5rem !important;
            margin-left: 0 !important;
        }

        .me-lg-3 {
            margin-left: 0.75rem !important;
            margin-right: 0 !important;
        }

        .ms-lg-3 {
            margin-right: 0.75rem !important;
            margin-left: 0 !important;
        }

        .me-lg-4 {
            margin-left: 1rem !important;
            margin-right: 0 !important;
        }

        .ms-lg-4 {
            margin-right: 1rem !important;
            margin-left: 0 !important;
        }

        .me-lg-5 {
            margin-left: 1.5rem !important;
            margin-right: 0 !important;
        }

        .ms-lg-5 {
            margin-right: 1.5rem !important;
            margin-left: 0 !important;
        }

        .me-lg-6 {
            margin-left: 2rem !important;
            margin-right: 0 !important;
        }

        .ms-lg-6 {
            margin-right: 2rem !important;
            margin-left: 0 !important;
        }

        .me-lg-7 {
            margin-left: 3rem !important;
            margin-right: 0 !important;
        }

        .ms-lg-7 {
            margin-right: 3rem !important;
            margin-left: 0 !important;
        }

        .me-lg-8 {
            margin-left: 4rem !important;
            margin-right: 0 !important;
        }

        .ms-lg-8 {
            margin-right: 4rem !important;
            margin-left: 0 !important;
        }

        .me-lg-9 {
            margin-left: 6rem !important;
            margin-right: 0 !important;
        }

        .ms-lg-9 {
            margin-right: 6rem !important;
            margin-left: 0 !important;
        }

        .me-lg-auto {
            margin-left: auto !important;
            margin-right: 0 !important;
        }

        .ms-lg-auto {
            margin-right: auto !important;
            margin-left: 0 !important;
        }
    }

    @media (min-width: 1280px) {
        .me-xl-0 {
            margin-left: 0 !important;
            margin-right: auto !important;
        }

        .ms-xl-0 {
            margin-right: 0 !important;
            margin-left: auto !important;
        }

        .me-xl-1 {
            margin-left: 0.25rem !important;
            margin-right: 0 !important;
        }

        .ms-xl-1 {
            margin-right: 0.25rem !important;
            margin-left: 0 !important;
        }

        .me-xl-2 {
            margin-left: 0.5rem !important;
            margin-right: 0 !important;
        }

        .ms-xl-2 {
            margin-right: 0.5rem !important;
            margin-left: 0 !important;
        }

        .me-xl-3 {
            margin-left: 0.75rem !important;
            margin-right: 0 !important;
        }

        .ms-xl-3 {
            margin-right: 0.75rem !important;
            margin-left: 0 !important;
        }

        .me-xl-4 {
            margin-left: 1rem !important;
            margin-right: 0 !important;
        }

        .ms-xl-4 {
            margin-right: 1rem !important;
            margin-left: 0 !important;
        }

        .me-xl-5 {
            margin-left: 1.5rem !important;
            margin-right: 0 !important;
        }

        .ms-xl-5 {
            margin-right: 1.5rem !important;
            margin-left: 0 !important;
        }

        .me-xl-6 {
            margin-left: 2rem !important;
            margin-right: 0 !important;
        }

        .ms-xl-6 {
            margin-right: 2rem !important;
            margin-left: 0 !important;
        }

        .me-xl-7 {
            margin-left: 3rem !important;
            margin-right: 0 !important;
        }

        .ms-xl-7 {
            margin-right: 3rem !important;
            margin-left: 0 !important;
        }

        .me-xl-8 {
            margin-left: 4rem !important;
            margin-right: 0 !important;
        }

        .ms-xl-8 {
            margin-right: 4rem !important;
            margin-left: 0 !important;
        }

        .me-xl-9 {
            margin-left: 6rem !important;
            margin-right: 0 !important;
        }

        .ms-xl-9 {
            margin-right: 6rem !important;
            margin-left: 0 !important;
        }

        .me-xl-auto {
            margin-left: auto !important;
            margin-right: 0 !important;
        }

        .ms-xl-auto {
            margin-right: auto !important;
            margin-left: 0 !important;
        }
    }

    .pe-0 {
        padding-left: 0 !important;
        padding-right: inherit !important;
    }

    .ps-0 {
        padding-right: 0 !important;
        padding-left: auto !important;
    }

    .pe-1 {
        padding-left: 0.25rem !important;
        padding-right: 0 !important;
    }

    .ps-1 {
        padding-right: 0.25rem !important;
        padding-left: 0 !important;
    }

    .pe-2 {
        padding-left: 0.5rem !important;
        padding-right: 0 !important;
    }

    .ps-2 {
        padding-right: 0.5rem !important;
        padding-left: 0 !important;
    }

    .pe-3 {
        padding-left: 0.75rem !important;
        padding-right: 0 !important;
    }

    .ps-3 {
        padding-right: 0.75rem !important;
        padding-left: 0 !important;
    }

    .pe-4 {
        padding-left: 1rem !important;
        padding-right: 0 !important;
    }

    .ps-4 {
        padding-right: 1rem !important;
        padding-left: 0 !important;
    }

    .pe-5 {
        padding-left: 1.5rem !important;
        padding-right: 0 !important;
    }

    .ps-5 {
        padding-right: 1.5rem !important;
        padding-left: 0 !important;
    }

    .pe-6 {
        padding-left: 2rem !important;
        padding-right: 0 !important;
    }

    .ps-6 {
        padding-right: 2rem !important;
        padding-left: 0 !important;
    }

    .pe-7 {
        padding-left: 3rem !important;
        padding-right: 0 !important;
    }

    .ps-7 {
        padding-right: 3rem !important;
        padding-left: 0 !important;
    }

    .pe-8 {
        padding-left: 4rem !important;
        padding-right: 0 !important;
    }

    .ps-8 {
        padding-right: 4rem !important;
        padding-left: 0 !important;
    }

    .pe-9 {
        padding-left: 6rem !important;
        padding-right: 0 !important;
    }

    .ps-9 {
        padding-right: 6rem !important;
        padding-left: 0 !important;
    }

    @media (min-width: 576px) {
        .pe-sm-0 {
            padding-left: 0 !important;
            padding-right: auto !important;
        }

        .ps-sm-0 {
            padding-right: 0 !important;
            padding-left: auto !important;
        }

        .pe-sm-1 {
            padding-left: 0.25rem !important;
            padding-right: 0 !important;
        }

        .ps-sm-1 {
            padding-right: 0.25rem !important;
            padding-left: 0 !important;
        }

        .pe-sm-2 {
            padding-left: 0.5rem !important;
            padding-right: 0 !important;
        }

        .ps-sm-2 {
            padding-right: 0.5rem !important;
            padding-left: 0 !important;
        }

        .pe-sm-3 {
            padding-left: 0.75rem !important;
            padding-right: 0 !important;
        }

        .ps-sm-3 {
            padding-right: 0.75rem !important;
            padding-left: 0 !important;
        }

        .pe-sm-4 {
            padding-left: 1rem !important;
            padding-right: 0 !important;
        }

        .ps-sm-4 {
            padding-right: 1rem !important;
            padding-left: 0 !important;
        }

        .pe-sm-5 {
            padding-left: 1.5rem !important;
            padding-right: 0 !important;
        }

        .ps-sm-5 {
            padding-right: 1.5rem !important;
            padding-left: 0 !important;
        }

        .pe-sm-6 {
            padding-left: 2rem !important;
            padding-right: 0 !important;
        }

        .ps-sm-6 {
            padding-right: 2rem !important;
            padding-left: 0 !important;
        }

        .pe-sm-7 {
            padding-left: 3rem !important;
            padding-right: 0 !important;
        }

        .ps-sm-7 {
            padding-right: 3rem !important;
            padding-left: 0 !important;
        }

        .pe-sm-8 {
            padding-left: 4rem !important;
            padding-right: 0 !important;
        }

        .ps-sm-8 {
            padding-right: 4rem !important;
            padding-left: 0 !important;
        }

        .pe-sm-9 {
            padding-left: 6rem !important;
            padding-right: 0 !important;
        }

        .ps-sm-9 {
            padding-right: 6rem !important;
            padding-left: 0 !important;
        }
    }

    @media (min-width: 768px) {
        .pe-md-0 {
            padding-left: 0 !important;
            padding-right: auto !important;
        }

        .ps-md-0 {
            padding-right: 0 !important;
            padding-left: auto !important;
        }

        .pe-md-1 {
            padding-left: 0.25rem !important;
            padding-right: 0 !important;
        }

        .ps-md-1 {
            padding-right: 0.25rem !important;
            padding-left: 0 !important;
        }

        .pe-md-2 {
            padding-left: 0.5rem !important;
            padding-right: 0 !important;
        }

        .ps-md-2 {
            padding-right: 0.5rem !important;
            padding-left: 0 !important;
        }

        .pe-md-3 {
            padding-left: 0.75rem !important;
            padding-right: 0 !important;
        }

        .ps-md-3 {
            padding-right: 0.75rem !important;
            padding-left: 0 !important;
        }

        .pe-md-4 {
            padding-left: 1rem !important;
            padding-right: 0 !important;
        }

        .ps-md-4 {
            padding-right: 1rem !important;
            padding-left: 0 !important;
        }

        .pe-md-5 {
            padding-left: 1.5rem !important;
            padding-right: 0 !important;
        }

        .ps-md-5 {
            padding-right: 1.5rem !important;
            padding-left: 0 !important;
        }

        .pe-md-6 {
            padding-left: 2rem !important;
            padding-right: 0 !important;
        }

        .ps-md-6 {
            padding-right: 2rem !important;
            padding-left: 0 !important;
        }

        .pe-md-7 {
            padding-left: 3rem !important;
            padding-right: 0 !important;
        }

        .ps-md-7 {
            padding-right: 3rem !important;
            padding-left: 0 !important;
        }

        .pe-md-8 {
            padding-left: 4rem !important;
            padding-right: 0 !important;
        }

        .ps-md-8 {
            padding-right: 4rem !important;
            padding-left: 0 !important;
        }

        .pe-md-9 {
            padding-left: 6rem !important;
            padding-right: 0 !important;
        }

        .ps-md-9 {
            padding-right: 6rem !important;
            padding-left: 0 !important;
        }
    }

    @media (min-width: 992px) {
        .pe-lg-0 {
            padding-left: 0 !important;
            padding-right: auto !important;
        }

        .ps-lg-0 {
            padding-right: 0 !important;
            padding-left: auto !important;
        }

        .pe-lg-1 {
            padding-left: 0.25rem !important;
            padding-right: 0 !important;
        }

        .ps-lg-1 {
            padding-right: 0.25rem !important;
            padding-left: 0 !important;
        }

        .pe-lg-2 {
            padding-left: 0.5rem !important;
            padding-right: 0 !important;
        }

        .ps-lg-2 {
            padding-right: 0.5rem !important;
            padding-left: 0 !important;
        }

        .pe-lg-3 {
            padding-left: 0.75rem !important;
            padding-right: 0 !important;
        }

        .ps-lg-3 {
            padding-right: 0.75rem !important;
            padding-left: 0 !important;
        }

        .pe-lg-4 {
            padding-left: 1rem !important;
            padding-right: 0 !important;
        }

        .ps-lg-4 {
            padding-right: 1rem !important;
            padding-left: 0 !important;
        }

        .pe-lg-5 {
            padding-left: 1.5rem !important;
            padding-right: 0 !important;
        }

        .ps-lg-5 {
            padding-right: 1.5rem !important;
            padding-left: 0 !important;
        }

        .pe-lg-6 {
            padding-left: 2rem !important;
            padding-right: 0 !important;
        }

        .ps-lg-6 {
            padding-right: 2rem !important;
            padding-left: 0 !important;
        }

        .pe-lg-7 {
            padding-left: 3rem !important;
            padding-right: 0 !important;
        }

        .ps-lg-7 {
            padding-right: 3rem !important;
            padding-left: 0 !important;
        }

        .pe-lg-8 {
            padding-left: 4rem !important;
            padding-right: 0 !important;
        }

        .ps-lg-8 {
            padding-right: 4rem !important;
            padding-left: 0 !important;
        }

        .pe-lg-9 {
            padding-left: 6rem !important;
            padding-right: 0 !important;
        }

        .ps-lg-9 {
            padding-right: 6rem !important;
            padding-left: 0 !important;
        }
    }

    @media (min-width: 1280px) {
        .pe-xl-0 {
            padding-left: 0 !important;
            padding-right: auto !important;
        }

        .ps-xl-0 {
            padding-right: 0 !important;
            padding-left: auto !important;
        }

        .pe-xl-1 {
            padding-left: 0.25rem !important;
            padding-right: 0 !important;
        }

        .ps-xl-1 {
            padding-right: 0.25rem !important;
            padding-left: 0 !important;
        }

        .pe-xl-2 {
            padding-left: 0.5rem !important;
            padding-right: 0 !important;
        }

        .ps-xl-2 {
            padding-right: 0.5rem !important;
            padding-left: 0 !important;
        }

        .pe-xl-3 {
            padding-left: 0.75rem !important;
            padding-right: 0 !important;
        }

        .ps-xl-3 {
            padding-right: 0.75rem !important;
            padding-left: 0 !important;
        }

        .pe-xl-4 {
            padding-left: 1rem !important;
            padding-right: 0 !important;
        }

        .ps-xl-4 {
            padding-right: 1rem !important;
            padding-left: 0 !important;
        }

        .pe-xl-5 {
            padding-left: 1.5rem !important;
            padding-right: 0 !important;
        }

        .ps-xl-5 {
            padding-right: 1.5rem !important;
            padding-left: 0 !important;
        }

        .pe-xl-6 {
            padding-left: 2rem !important;
            padding-right: 0 !important;
        }

        .ps-xl-6 {
            padding-right: 2rem !important;
            padding-left: 0 !important;
        }

        .pe-xl-7 {
            padding-left: 3rem !important;
            padding-right: 0 !important;
        }

        .ps-xl-7 {
            padding-right: 3rem !important;
            padding-left: 0 !important;
        }

        .pe-xl-8 {
            padding-left: 4rem !important;
            padding-right: 0 !important;
        }

        .ps-xl-8 {
            padding-right: 4rem !important;
            padding-left: 0 !important;
        }

        .pe-xl-9 {
            padding-left: 6rem !important;
            padding-right: 0 !important;
        }

        .ps-xl-9 {
            padding-right: 6rem !important;
            padding-left: 0 !important;
        }
    }

    .text-start {
        text-align: right !important;
    }

    .text-end {
        text-align: left !important;
    }

    @media (min-width: 576px) {
        .text-sm-start {
            text-align: right !important;
        }

        .text-sm-end {
            text-align: left !important;
        }
    }

    @media (min-width: 768px) {
        .text-md-start {
            text-align: right !important;
        }

        .text-md-end {
            text-align: left !important;
        }
    }

    @media (min-width: 992px) {
        .text-lg-start {
            text-align: right !important;
        }

        .text-lg-end {
            text-align: left !important;
        }
    }

    @media (min-width: 1280px) {
        .text-xl-start {
            text-align: right !important;
        }

        .text-xl-end {
            text-align: left !important;
        }
    }

    .widget-spec li i {
        margin-left: 0.5rem;
        margin-right: 0;
    }

    .widget-info-right {
        text-align: left;
        float: left;
    }

    @media (max-width: 991px) {
        .widget-info-right {
            text-align: right;
            float: right;
        }
    }

    .wideget-user-info .wideget-user-warap .wideget-user-warap-r {
        margin-left: 0rem;
        margin-right: 10rem;
    }

    .widgetdate {
        float: left;
    }

    .widget-line-list li {
        padding: 5px 15px 0 20px;
    }

    .widgets-cards .wrp.icon-circle {
        margin-right: 0;
        margin-left: 15px;
    }

    .user-wideget .border-right {
        border-left: 1px solid #f4f4f4;
        border-right: 0;
    }

    .widget-user .widget-user-image {
        left: 0;
        margin-left: 0;
        right: 50%;
        margin-right: -45px;
    }

    .weather-card .bottom .weather-wrapper .forecast li .condition .temp .deg {
        margin-left: 0;
        margin-right: 3px;
    }

    .weather-card .bottom .weather-wrapper .forecast li .condition {
        float: left;
    }

    .weather-card .top:after {
        left: auto;
        right: 0;
    }

    .weather-card .top .weather-wrapper .mynav .icon {
        float: right;
    }

    .weather-card .top .weather-wrapper .mynav .icon1 {
        float: left;
    }

    .ribbon-top-right span {
        left: 0;
        right: -8px;
        transform: rotate(-45deg);
    }

    .ribbon-top-right::before {
        left: 0;
        right: 36px
    }

    .ribbon-top-right::after {
        right: auto;
        left: 0;
    }

    .ribbon-top-right {
        left: -10px;
        right: auto;
    }

    .ribbon-top-right::before,
    .ribbon-top-right::after {
        border-right-color: inherit;
        border-left-color: transparent
    }


    .ribbon-top-left {
        left: 0px;
        right: -10px;
    }

    .ribbon-top-left::before,
    .ribbon-top-left::after {
        border-right-color: transparent;
        border-left-color: inherit;
    }

    .ribbon-top-left::before {
        left: 50px;
        right: auto;
    }

    .ribbon-top-left::after {
        right: 0;
        left: auto;
    }

    .ribbon-top-left span {
        right: auto;
        left: -8px;
        transform: rotate(45deg);
    }

    .power-ribbon-top-left {
        left: 0;
        right: -9px;
    }

    .power-ribbon-top-left span {
        left: -5px;
        right: auto;
        transform: rotate(45deg);
    }

    .power-ribbon-top-left span i {
        transform: rotate(-45deg);
        padding-left: 0;
        padding-right: 7px;
    }

    .arrow-ribbon2:before {
        right: auto;
        left: -24px;
        border-right: 24px solid $primary;
        border-left: 0;
    }

    .arrow-ribbon2 {
        left: auto;
        right: -1px;
    }

    .arrow-ribbon.bg-pink:before {
        right: auto;
        left: -15px;
        border-left: 15px solid $secondary;
        border-right: 0;
    }

    .arrow-ribbon.bg-warning:before {
        right: auto;
        left: -15px;
        border-left: 0;
        border-right: 15px solid $yellow;
    }

    .arrow-ribbon.bg-info:before {
        right: auto;
        left: -15px;
        border-left: 0;
        border-right: 15px solid $info;
    }

    .arrow-ribbon {
        left: auto;
        right: 0px
    }

    .arrow-ribbon.bg-purple:before {
        right: auto;
        left: -15px;
        border-left: 0;
        border-right: 15px solid $purple;
    }

    .arrow-ribbon.bg-danger:before {
        right: auto;
        left: -15px;
        border-left: 0;
        border-right: 15px solid $danger;
    }

    .arrow-ribbon.bg-primary:before {
        right: auto;
        left: -15px;
    }

    .arrow-ribbon.bg-secondary:before {
        right: auto;
        left: -15px;
        border-left: 0;
        border-right: 15px solid $secondary
    }

    .arrow-ribbon.bg-success:before {
        right: auto;
        left: -15px;
        border-left: 0;
        border-right: 15px solid $success;
    }

    .ribbon1 span:before {
        left: 0;
        right: -6px;
    }

    .ribbon1 span:after {
        left: 0;
        right: -8px;
        border-radius: 0 0 8px 8px;
    }

    .ribbon1 span {
        border-top-right-radius: 0;
        border-top-left-radius: 8px;
    }

    .ribbon1:after {
        border-left: 24px solid transparent;
        border-right: 20px solid transparent;
    }

    .ribbon1 {
        right: auto;
        left: 10px;
    }

    .arrow-ribbon-2:before {
        right: auto;
        left: -24px;
        border-left: 0;
        border-right: 24px solid $primary;
    }

    .error-img:before {
        right: auto;
        left: 0;
    }

    .inbox-message .message-body {
        margin-left: 0;
        margin-right: 85px;
    }

    .inbox-message .message-avatar {
        left: 0px;
        right: 30px;
        transform: translateY(50%);
    }

    .online {
        margin-left: 0px;
        margin-right: 20px;
    }

    .online-status .status {
        transform: translateX(60px) translateY(-2px);
    }

    .mail-chats li.chat-persons.user {
        padding: 5px 15px 0px 10px;
    }

    .mail-chats li.chat-persons a span.pro-pic {
        float: right;
        margin-right: 0px;
        margin-left: 20px;
    }

    .mail-chats {
        padding: 10px 0px 10px 50px;
    }

    .attachment-mail ul li span {
        float: left;
    }

    .attachment-mail .file-name {
        float: right;
    }

    .attachment-mail ul li {
        float: right;
        margin-right: 0px;
        margin-left: 10px;
    }

    .inbox-pagination a.np-btn {
        margin-left: 0;
        margin-right: 5px;
    }

    .inbox-pagination li span {
        margin-right: 0;
        margin-left: 5px;
    }

    .mail-option .chk-all,
    .mail-option .btn-group {
        margin-right: 0;
        margin-left: 5px;
    }

    ul.inbox-pagination {
        float: left;
    }

    ul.inbox-pagination li {
        float: right;
    }

    .inbox-head .sr-btn {
        border-radius: 4px 0 0 4px;
    }

    .inbox-head .sr-input {
        border-radius: 0 4px 4px 0;
        float: right;
    }

    .inbox-head {
        border-radius: 0 0 0 4px;
    }

    ul.inbox-nav li a i {
        padding-right: 0px;
        padding-left: 10px;
    }

    .user-head .user-name {
        margin: 0 10px 0 0px;
    }

    .user-head .inbox-avatar {
        float: right;
    }

    .mail-box .sm-side {
        border-radius: 0 4px 4px 0;
    }

    .mail-box .lg-side {
        border-radius: 4px 0 0 4px;
    }

    .msg time:before {
        margin-right: 0;
        margin-left: 4px;
    }

    .msg time {
        float: left;
    }

    #chat-message {
        right: 0px;
        left: 20px;
    }

    .chat-line-friend .chat-message {
        margin-left: 2.5rem;
        margin-right: .5rem;
    }

    .chat-line-friend .chat-message:after {
        right: -5px;
        left: auto;
        border-right-width: 0;
        border-left-width: 5px;
        border-right: 0;
        border-left: 5px solid #f9faff;
    }

    .chat-message:after {
        right: 0;
        left: -5px;
        border-left: 0;
        border-right: 6px solid $blue;
    }

    .chat-message {
        text-align: right;
        margin: 0 2.5rem 0 .5rem;
    }

    .chat-line {
        text-align: left;
    }

    .gm-ui-hover-effect {
        right: auto !important;
        left: 0 !important;
    }

    .map-content-width .mCSB_inside>.mCSB_container {
        margin-right: auto;
        margin-left: 0;
    }

    .map-content-width .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        float: left;
    }

    .cal1 .clndr .clndr-table tr .day {
        border-left: 0;
        border-right: 1px solid $border;
    }

    .cal1 .clndr .clndr-table tr .day:last-child {
        border-left: 1px solid $border;
        border-right: 0;
    }

    .cal1 .clndr .clndr-table .header-days .header-day {
        border-left: 0;
        border-right: 1px solid rgba(5, 117, 230, 0.1);
    }

    .cal1 .clndr .clndr-table tr .empty,
    .cal1 .clndr .clndr-table tr .adjacent-month,
    .cal1 .clndr .clndr-table tr .my-empty,
    .cal1 .clndr .clndr-table tr .my-adjacent-month {
        border-left: 0;
        border-right: 1px solid #79c2b2;
    }

    @media (min-width: 992px) {
        .admin-navbar .sub-item .sub-with-sub ul {
            padding-left: inherit;
            padding-right: 0;
            right: 0;
            left: -200px;
        }

        .admin-navbar .nav-item.with-sub>a::after {
            margin-left: 0;
            margin-right: 8px;
        }

        .admin-navbar .nav-link .square-8 {
            margin-left: 0;
            margin-right: 5px;
        }
    }

    @media (max-width: 991px) {
        .admin-navbar .nav-link {
            text-align: right;
        }

        .admin-navbar .sub-item .sub-with-sub ul li a {
            padding-left: 0;
            padding-right: 20px;
        }
    }

    .admin-navbar .sub-item .sub-with-sub::after {
        right: 0;
        left: 20px;
    }

    .admin-navbar .sub-item .sub-with-sub ul {
        padding-left: 0;
        padding-right: 15px;
    }

    .admin-navbar .sub-item ul {
        text-align: right;
    }

    .admin-navbar .nav-link i {
        margin-right: 0;
        margin-left: 8px;
    }

    .admin-navbar .nav-link .square-8 {
        margin-left: 0;
        margin-right: 3px;
    }

    .admin-navbar .mega-dropdown .sub-item .section-label {
        text-align: right;
    }

    .admin-navbar .sub-item {
        left: auto;
        right: 0;
    }

    .admin-navbar .nav-item.active+.nav-item .nav-link {
        border-left-width: inherit;
        border-right-width: 0;
    }

    .admin-navbar .nav-item:last-child .nav-link {
        border-right-width: 0;
        border-left-width: 1px;
    }

    .admin-navbar .nav-item.with-sub>a::after {
        margin-left: 0;
        margin-right: 4px;
    }

    .selectize-input .image {
        margin: -3px -5px -3px .75rem;
    }

    .selectize-dropdown .image,
    .selectize-input .image {
        margin: -1px -4px -1px .5rem;
        float: right;
    }

    .selectize-control.single .selectize-input:after {
        right: 0;
        left: 12px;
    }

    .selectize-dropdown {
        border-radius: 3px 3px 0 0;
    }

    .selectize-control.multi .selectize-input.has-items {
        padding: 7px 7px 4px 0.75rem;
    }

    .selectize-input.dropdown-active {
        border-radius: 0 0 3px 3px;
    }

    .selectize-control.multi .selectize-input>div {
        margin: 0 0 3px 3px;
    }

    .selectize-input>input {
        margin: 0 0 0 2px !important;
    }

    .selectize-control.plugin-remove_button [data-value].active .remove {
        border-left-color: transparent;
        border-right-color: #cacaca;
    }

    .selectize-control.plugin-remove_button .disabled [data-value] .remove {
        border-left-color: transparent;
        border-right-color: $white;
    }

    .selectize-control.plugin-remove_button .remove-single {
        right: 0;
        left: 28px;
    }

    .desktoplogo {
        float: right;
    }

    .horizontalMenu {
        float: left;

        >.horizontalMenu-list {
            text-align: right;

            >li {
                float: right;

                &:last-child>a {
                    border-right: inherit;
                    border-left: 0px;
                }

                >a {
                    .fa {
                        margin-right: 0;
                        margin-left: 7px;
                    }

                    .popover-arrow:after {
                        float: left;
                        right: 0;
                        left: 11px;
                        margin: 0 14px 0 0;
                        text-align: left;
                    }
                }

                &.rightmenu {
                    float: left;

                    a {
                        padding: 0px 20px 0px 30px;
                        border-right: inherit;
                        border-left: none;
                    }

                    float: left;
                }
            }
        }
    }

    .horizontalMenu>.horizontalMenu-list>li {
        &.rightmenu {
            >.topmenusearch {
                float: left;

                input {
                    float: left;
                    right: auto;
                    left: 0;
                }

                .btnstyle {
                    right: auto;
                    left: 0;
                }
            }
        }

        > {
            ul.sub-menu {
                >li {
                    > {
                        a {
                            border-right: inherit;
                            border-left: 0 none;
                            text-align: right;

                            >.fa {
                                margin-right: 0;
                                margin-left: 9px;
                                transform: rotate(180deg);
                            }
                        }

                        ul.sub-menu {
                            left: 0;
                            right: 100%;

                            >li {
                                > {
                                    a {
                                        border-right: inherit;
                                        border-left: 0 none;
                                        text-align: right;

                                        >.fa {
                                            margin-right: 0;
                                            margin-left: 9px;
                                            transform: rotate(180deg);
                                        }
                                    }

                                    ul.sub-menu {
                                        left: 0;
                                        right: 100%;

                                        >li {
                                            >a {
                                                border-right: inherit;
                                                border-left: 0 none;
                                                text-align: right;

                                                >.fa {
                                                    margin-right: 0;
                                                    margin-left: 9px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .horizontal-megamenu {
                left: auto;
                right: 0;
                text-align: right;

                .title {
                    padding: 5px 0 5px 5px;
                    text-align: right;
                }

                .link-list li {
                    text-align: right;

                    a {
                        border-right: inherit;
                        border-left: none;
                        text-align: right;
                    }
                }

                li {
                    .fa {
                        margin-right: 0;
                        margin-left: 5px;
                    }
                }

                li .fa {
                    margin-right: 0;
                    margin-left: 5px;
                }

                .menu_form {
                    input {
                        &[type="submit"] {
                            float: left;
                            margin-right: 0;
                            margin-left: 15px;
                        }

                        &[type="button"] {
                            float: left;
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 1024px) {
        .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list {
            border-left: 0;
            border-right: 1px solid $border;
        }
    }

    @media only screen and (max-width: 991px) {
        .horizontalMenu {
            left: auto;
            right: 0;

            >.horizontalMenu-list {
                margin-left: auto;
                margin-right: -240px;

                >li {
                    border-right: inherit;
                    border-left: none;

                    >a {
                        padding: 12px 17px 12px 32px;
                        text-align: right;
                        border-right: inherit;
                        border-left: solid 0px;
                    }

                    > {
                        ul.sub-menu {
                            >li {
                                >a {
                                    padding: 10px 16px 10px 0px;
                                }

                                span+a {
                                    padding-left: auto;
                                    padding-left: 30px;
                                }
                            }

                            >li>ul.sub-menu {
                                left: auto;
                                right: 100%;

                                >li {
                                    >a {
                                        padding: 10px 26px 10px 0px;
                                    }

                                    span+a {
                                        padding-left: auto;
                                        padding-left: 30px;
                                    }

                                    >ul.sub-menu {
                                        left: auto;
                                        right: 100%;

                                        >li {
                                            >a {
                                                padding: 10px 34px 10px 0px;
                                            }

                                            span+a {
                                                padding-left: auto;
                                                padding-left: 30px;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .horizontal-megamenu {
                            .title {
                                padding: 10px 0px 10px 8px;
                            }
                        }
                    }
                }
            }
        }

        .horizontal-header {
            left: auto;
            right: 0;
        }

        .callusbtn {
            left: 5px;
            right: auto;
        }

        .animated-arrow {
            right: 0;
            left: auto;
            padding: 16px 0px 16px 35px;
            margin: 11px 15px 0 0px;
        }

        .overlapblackbg {
            right: auto;
            left: 0;
        }

        &.active {
            .horizontalMenucontainer {
                margin-left: 0;
                margin-right: 240px;
            }

            .horizontalMenu {
                >.horizontalMenu-list {
                    margin-left: auto;
                    margin-right: 0;
                }
            }
        }

        .horizontalMenu>.horizontalMenu-list>li {
            >.horizontalMenu-click {
                right: auto;
                left: 0;
            }

            > {
                .horizontalMenu-click>i {
                    margin-right: auto;
                    margin-left: 10px;
                    float: left;
                }

                ul.sub-menu>li .horizontalMenu-click02 {
                    right: auto;
                    left: 0;

                    >i {
                        float: left;
                    }
                }
            }
        }
    }


    .horizontalMenu-list {

        .sub-menu:before,
        .halfdiv:before {
            left: auto;
            right: 12px;
        }

        .sub-menu {
            &:after {
                left: auto;
                right: 12px;
            }
        }

        .halfdiv:after {
            right: 12px;
            left: auto;
        }

        .sub-menu {

            &:before,
            &:after {
                left: auto;
                right: 12px;
            }
        }

        .halfdiv {

            &:before,
            &:after {
                left: auto;
                right: 12px;
            }
        }
    }

    .selectize-dropdown-header {
        border-radius: 0 0 3px 3px;
    }

    .selectize-dropdown-header.btn-close {
        right: auto;
        left: 8px;
    }

    .selectize-dropdown.plugin-optgroup_columns .optgroup {
        border-right: 0;
        border-left: 1px solid #f2f2f2;
        float: right;
    }

    .selectize-dropdown.plugin-optgroup_columns .optgroup:last-child {
        border-left: inherit;
        border-right: 0 none;
    }

    .selectize-control.plugin-remove_button [data-value] {
        padding-right: 0 !important;
        padding-left: 24px !important;
    }

    .selectize-control.plugin-remove_button [data-value] .remove {
        right: auto;
        left: 0;
        border-left: 0;
        border-right: 1px solid #d0d0d0;
        border-radius: 2px 0 0 2px;
    }

    .alert-dismissible {
        padding-left: auto;
        padding-left: 3.90625rem;

        .btn-close {
            left: 0;
            right: auto;
        }
    }

    .alert-icon {
        padding-right: 3rem;
        padding-left: inherit;

        >i {
            left: auto;
            right: 1rem;
        }
    }

    .alert-avatar {
        padding-left: 1.25rem;
        padding-right: 3.75rem;

        .avatar {
            left: auto;
            right: .75rem;
        }
    }

    .avatar-status {
        right: 0;
        left: -2px;
    }

    .avatar-list .avatar:not(:last-child) {
        margin-left: .5rem;
        margin-right: 0;
    }

    .avatar-list-stacked .avatar {
        margin-right: 0 !important;
        margin-left: -.8em !important;
    }

    .badgetext {
        float: left;
    }

    .badge-offer {
        left: 0;
        right: 15px;

        &.bg-danger:after,
        &.bg-primary:after,
        &.bg-secondary:after,
        &.bg-success:after,
        &.bg-info:after,
        &.bg-warning:after,
        &.bg-purple:after,
        &.bg-pink:after {
            left: auto;
            right: 0;
        }
    }

    .badge-offer1 {
        right: auto;
        left: 15px;

        &.bg-danger:after,
        &.bg-primary:after,
        &.bg-secondary:after,
        &.bg-success:after,
        &.bg-info:after,
        &.bg-warning:after,
        &.bg-purple:after,
        &.bg-pink:after {
            left: auto;
            right: 0;
        }
    }

    .badge-offer {
        left: 0;
        right: 15px;

        .badge-offer {
            left: 0;
            right: 15px;

            &.bg-danger:after,
            &.bg-primary:after,
            &.bg-secondary:after,
            &.bg-success:after,
            &.bg-info:after,
            &.bg-warning:after,
            &.bg-purple:after,
            &.bg-pink:after {
                left: auto;
                right: 0;
            }
        }

        .badge-offer1 {
            right: 0;
            left: 15px;

            &.bg-danger:after,
            &.bg-primary:after,
            &.bg-secondary:after,
            &.bg-success:after,
            &.bg-info:after,
            &.bg-warning:after,
            &.bg-purple:after,
            &.bg-pink:after {
                left: auto;
                right: 0;
            }
        }

        .offer .shape {
            border-width: 0 0 40px 70px;
            float: left;
            right: auto;
            left: 0;
        }

        .offer-success .shape {
            border-color: transparent transparent transparent $success;
        }

        .offer-default .shape {
            border-color: transparent transparent transparent #18113c;
        }

        .offer-info .shape {
            border-color: transparent transparent transparent $info;
        }

        .offer-warning .shape {
            border-color: transparent transparent transparent #fb9505;
        }

        .offer-primary .shape {
            border-color: transparent transparent transparent #6964f7;
        }

        .shape-text {
            right: auto;
            left: -47px;
            transform: rotate(-30deg);
        }

        &.bg-danger:after,
        &.bg-primary:after,
        &.bg-secondary:after,
        &.bg-success:after,
        &.bg-info:after,
        &.bg-warning:after,
        &.bg-purple:after,
        &.bg-pink:after {
            left: auto;
            right: 0;
        }
    }

    .badge-offer1 {
        right: 0;
        left: 15px;

        &.bg-danger:after,
        &.bg-primary:after,
        &.bg-secondary:after,
        &.bg-success:after,
        &.bg-info:after,
        &.bg-warning:after,
        &.bg-purple:after,
        &.bg-pink:after {
            left: auto;
            right: 0;
        }
    }

    .offer .shape {
        border-width: 0 0 40px 70px;
        float: left;
        right: auto;
        left: 0;
    }

    .offer-danger .shape {
        border-color: transparent transparent transparent $danger;
    }

    .offer-success .shape {
        border-color: transparent transparent transparent $success;
    }

    .offer-default .shape {
        border-color: transparent transparent transparent #18113c;
    }

    .offer-info .shape {
        border-color: transparent transparent transparent $info;
    }

    .offer-warning .shape {
        border-color: transparent transparent transparent #fb9505;
    }

    .offer-primary .shape {
        border-color: transparent transparent transparent #6964f7;
    }

    .shape-text {
        right: auto;
        left: -47px;
        transform: rotate(-30deg);
    }

    dd {
        margin-left: auto;
        margin-right: 0;
    }

    caption {
        text-align: right;
    }

    .breadcrumb {
        left: auto;
        right: 0;
    }

    .breadcrumb-1 ol,
    .breadcrumb-2 ol {
        text-align: left;
    }

    .breadcrumb-1 li,
    .breadcrumb-2 li {
        text-align: left;
    }

    @media (max-width: 480px) {
        .breadcrumb-item+.breadcrumb-item {
            padding-left: inherit;
            padding-right: 0;
        }
    }


    .btn.btn-app {
        margin: 0 10px 10px 0;

        >.badge {
            right: auto;
            left: -10px;
        }
    }

    .btn-list> {

        .btn:not(:last-child),
        .dropdown:not(:last-child) {
            margin-right: auto;
            margin-left: .5rem;
        }
    }

    .btn-loading {
        &:after {
            border-right-color: inherit !important;
            border-left-color: transparent !important;
            left: 0;
            right: calc(50% - (1.4em / 2));
        }

        &.btn-sm:after {
            left: 0;
            right: calc(50% - (1em / 2));
        }
    }

    .btn-group-sm>.btn-loading.btn:after {
        right: calc(50% - (1em / 2));
        left: 0;
    }

    .btn-group {
        .btn+ {

            .btn,
            .btn-group {
                margin-left: 0;
                margin-right: -1px;
            }
        }

        .btn-group+ {

            .btn,
            .btn-group {
                margin-left: 0;
                margin-right: -1px;
            }
        }
    }

    .btn-group-vertical {
        .btn+ {

            .btn,
            .btn-group {
                margin-left: 0;
                margin-right: -1px;
            }
        }

        .btn-group+ {

            .btn,
            .btn-group {
                margin-left: 0;
                margin-right: -1px;
            }
        }
    }

    .btn-group> {
        .btn {
            &:first-child {
                margin-left: auto;
                margin-right: 0;
            }

            &:not(:last-child):not(.dropdown-toggle) {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        .btn-group {
            &:not(:last-child)>.btn {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }

            &:not(:first-child)>.btn {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    .btn-group-vertical> {
        .btn+ {

            .btn,
            .btn-group {
                margin-left: auto;
                margin-right: 0;
            }
        }

        .btn-group+ {

            .btn,
            .btn-group {
                margin-right: 0;
                margin-left: auto;
            }
        }
    }

    .btn-animation .btn-loaders {
        padding-right: 0.5rem;
        padding-left: 46px;

        &:after {
            border-right-color: inherit !important;
            border-left-color: transparent !important;
            right: auto !important;
            left: 20px !important;
        }
    }

    .btn-close {
        float: left;
    }

    .card-link+.card-link {
        margin-left: 0;
        margin-right: 1.5rem;
    }

    @media (min-width: 576px) {
        .card-group>.card {
            +.card {
                margin-left: auto;
                margin-right: 0;
                border-left: inherit;
                border-right: 0;
            }

            &:first-child {

                .card-img-top,
                .card-header {
                    border-top-right-radius: 3px;
                    border-top-left-radius: 0;
                }

                .card-img-bottom,
                .card-footer {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 3px;
                }
            }

            &:last-child {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                .card-img-top,
                .card-header {
                    border-top-left-radius: 3px;
                    border-top-right-radius: 0;
                }

                .card-img-bottom,
                .card-footer {
                    border-bottom-left-radius: 3px;
                    border-bottom-right-radius: 0;
                }
            }
        }
    }

    .card-blog-overlay:before,
    .card-blog-overlay1:before,
    .card-blog-overlay2:before {
        right: auto;
        left: 0;
    }


    .card-status-left {
        right: 0;
        left: auto;
        border-radius: 0 3px 3px 0;
    }

    .reg {
        float: left;
    }

    .card-table tr {

        td:first-child,
        th:first-child {
            padding-left: 1.5rem;
        }

        td:last-child,
        th:last-child {
            padding-left: 1.5rem;
        }
    }

    .card-link+.card-link {
        margin-left: 0;
        margin-right: 1rem;
    }

    .card-img-overlay1 .widgetbox {
        left: auto;
        right: 0;
    }

    .card-blog-overlay6:before,
    .card-blog-img:before {
        right: auto;
        left: 0;
    }

    .card-aside-img a {
        left: auto;
        right: 0;
    }

    .card-pay .tabs-menu li a {
        border-right: 0;
        border-left: 1px solid #f3f3f3;
    }

    .card-video:before {
        left: 0;
        right: auto;
    }

    .card-options {
        margin-left: -.5rem;
        margin-right: auto;

        a:not(.btn) {
            margin-right: .5rem;
            margin-left: 0;
        }
    }


    .carousel-indicators {
        padding-left: inherit;
        padding-right: 0;

        li {

            &::before,
            &::after {
                left: auto;
                right: 0;
            }
        }
    }

    .carousel-indicators1 {
        padding-left: inherit;
        padding-right: 0;

        li {

            &::before,
            &::after {
                left: auto;
                right: 0;
            }
        }
    }

    .carousel-indicators2 {
        right: auto;
        left: 10px;
        padding-left: inherit;
        padding-right: 0;

        li {

            &::before,
            &::after {
                left: auto;
                right: 0;
            }
        }
    }

    .carousel-indicators3 {
        left: auto;
        right: 10px;
        padding-left: inherit;
        padding-right: 0;

        li {

            &::before,
            &::after {
                left: auto;
                right: 0;
            }
        }
    }

    .carousel-indicators4 {
        right: auto;
        left: 10px;
        padding-left: inherit;
        padding-right: 0;

        li {

            &::before,
            &::after {
                left: auto;
                right: 0;
            }
        }
    }

    .carousel-indicators5 {
        left: auto;
        right: 10px;
        padding-left: inherit;
        padding-right: 0;

        li {

            &::before,
            &::after {
                left: auto;
                right: 0;
            }
        }
    }

    .owl-carousel-icons5 .owl-nav {

        .owl-prev,
        .owl-next {
            left: -24px;
            right: auto;
        }
    }


    .carousel-control-next {
        right: 0;
        left: auto;
        transform: rotate(180deg);
    }

    .carousel-control-prev {
        right: auto;
        left: 0;
        transform: rotate(180deg);
    }

    .dropdown-toggle {
        &::after {
            margin-left: 0;
            margin-right: 0.255em;
        }

        &:empty::after {
            margin-left: auto;
            margin-right: 0;
        }
    }

    .dropdown-menu {
        left: auto;
        right: 0;
        float: right;
        text-align: right;
    }

    .dropdown-menu-end {
        right: auto;
        left: 0;
    }

    .dropup .dropdown-toggle {
        &::after {
            margin-left: 0;
            margin-right: 0.255em;
        }

        &:empty::after {
            margin-left: auto;
            margin-right: 0;
        }
    }

    .dropend {
        .dropdown-menu {
            right: 100%;
            left: auto;
            margin-left: 0;
            margin-right: 0.125rem;
        }

        .dropdown-toggle {
            &::after {
                margin-left: 0;
                margin-right: 0.255em;
                border-right: 0.3em solid;
                border-left: 0;
            }

            &:empty::after {
                margin-left: auto;
                margin-right: 0;
            }
        }
    }

    .dropstart {
        .dropdown-menu {
            right: auto;
            left: 100%;
            margin-right: 0;
            margin-left: 0.125rem;
        }

        .dropdown-toggle {
            &::after {
                margin-left: 0;
                margin-right: 0.255em;
            }

            &::before {
                margin-right: 0;
                margin-left: 0.255em;
                border-right: 0;
                border-left: 0.3em solid;
            }

            &:empty::after {
                margin-left: auto;
                margin-right: 0;
            }
        }
    }

    .dropdown-menu {

        &[data-popper-placement^="top"],
        &[data-popper-placement^="right"],
        &[data-popper-placement^="bottom"],
        &[data-popper-placement^="left"] {
            right: 0 !important;
            left: auto !important;
        }
    }

    .dropdown-toggle-split::after,
    .dropup .dropdown-toggle-split::after,
    .dropend .dropdown-toggle-split::after {
        margin-left: auto;
        margin-right: 0;
    }

    .dropstart .dropdown-toggle-split::before {
        margin-right: auto;
        margin-left: 0;
    }

    .dropdown-menu {
        left: auto;
        right: 0;
        float: right;
    }

    .dropdown-media-list .media-body {
        margin-left: 0;
        margin-right: 15px;
    }

    .dropdown-menu-arrow {

        &:before,
        &:after {
            right: auto;
            left: 12px;
        }

        &.dropdown-menu-end {

            &:before,
            &:after {
                left: 12px;
                right: auto;
            }
        }
    }

    .dropdown-toggle:empty:after {
        margin-left: auto;
        margin-right: 0;
    }

    .dropdown-icon {
        margin-right: -.5rem;
        margin-left: .5rem;
    }

    .form-check {
        padding-left: inherit;
        padding-right: 1.25rem;
    }

    .form-check-input {
        margin-left: auto;
        margin-right: -1.25rem;
    }

    .form-check-inline {
        padding-left: 0.75rem;
        margin-right: 0;

        .form-check-input {
            margin-right: 0;
            margin-left: 0.3125rem;
        }
    }

    @media (min-width: 576px) {
        .form-inline {
            .form-check {
                padding-left: inherit;
                padding-right: 0;
            }

            .form-check-input {
                margin-right: 0;
                margin-left: 0.25rem;
            }
        }
    }

    .form-label-small {
        float: left;
    }

    .input-icon .form-control {

        &:not(:last-child),
        &:not(:first-child) {
            padding-left: 0;
            padding-right: 2.5rem;
        }
    }

    .input-icon-addon {
        left: auto;
        right: 0;

        &:last-child {
            left: 0;
            right: auto;
        }
    }

    .fileinput-button {
        float: right;
        margin-right: 0;
        margin-left: 4px;

        input {
            direction: rtl;
            right: auto;
            left: 0;
        }
    }

    * html .fileinput-button {
        margin: 1px 0 0 -3px;
    }

    .input-indec {
        .quantity-left-minus.btn {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-right: inherit;
            border-left: 0;
        }

        .quantity-right-plus.btn {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-left: inherit;
            border-right: 0;
        }
    }

    .state-valid,
    .state-invalid {
        padding-right: 0.75rem;
        padding-left: 2rem;
    }

    .input-group> {
        .form-control+ {

            .form-control,
            .form-select,
            .form-file {
                margin-left: 0;
                margin-right: -1px;
            }
        }

        .form-select+ {

            .form-control,
            .form-select,
            .form-file {
                margin-left: 0;
                margin-right: -1px;
            }
        }

        .form-file+ {

            .form-control,
            .form-select,
            .form-file {
                margin-left: 0;
                margin-right: -1px;
            }
        }

        .form-control:not(:last-child),
        .form-select:not(:last-child) {
            border-top-right-radius: 7px;
            border-bottom-right-radius: 7px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        .form-control:not(:first-child),
        .form-select:not(:first-child) {
            border-top-left-radius: 7px;
            border-bottom-left-radius: 7px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        .form-file {
            &:not(:last-child) .form-file-label {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;

                &::after {
                    border-top-right-radius: 3px;
                    border-bottom-right-radius: 3px;
                    border-top-left-radius: 0;
                    border-bottom-left-radius: 0;
                }
            }

            &:not(:first-child) .form-file-label {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                &::after {
                    border-top-left-radius: 3px;
                    border-bottom-left-radius: 3px;
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }
            }
        }
    }

    .input-group-text {
        .btn+ {

            .btn,
            .input-group-text {
                margin-left: 0;
                margin-right: -1px;
            }
        }

        .input-group-text+ {

            .input-group-text,
            .btn {
                margin-left: 0;
                margin-right: -1px;
            }
        }

        .btn+ {

            .btn,
            .input-group-text {
                margin-left: 0;
                margin-right: -1px;
            }
        }

        .input-group-text+ {

            .input-group-text,
            .btn {
                margin-left: 0;
                margin-right: -1px;
            }
        }
    }

    .input-group>.input-group-text {
        > {

            .btn,
            .input-group-text {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &:not(:last-child)> {

            .btn,
            .input-group-text {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        &:last-child> {

            .btn:not(:last-child):not(.dropdown-toggle),
            .input-group-text:not(:last-child) {
                border-top-right-radius: 3px;
                border-bottom-right-radius: 3px;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }

        > {

            .btn,
            .input-group-text {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &:not(:first-child)> {

            .btn,
            .input-group-text {
                border-top-left-radius: 7px;
                border-bottom-left-radius: 7px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }

        &:first-child> {

            .btn:not(:first-child),
            .input-group-text:not(:first-child) {
                border-top-left-radius: 3px;
                border-bottom-left-radius: 3px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    .modal-open,
    .modal-backdrop.fade.show {
        padding-right: auto !important;
        padding-left: 0 !important;
    }

    .modal:before {
        margin-right: 0;
        margin-left: -4px;
    }

    .modal-dialog {
        text-align: right;
    }

    .modal-header .btn-close {
        margin: -1rem auto -1rem -1rem;
    }

    .modal-header .btn-close.btn {
        float: left;
    }

    .modal-footer> :not(:first-child) {
        margin-left: 0;
        margin-right: .25rem;
    }

    .modal-footer> :not(:last-child) {
        margin-right: 0;
        margin-left: .25rem;
    }

    .nav {
        padding-left: 0;
        padding-right: 0;
    }


    .nav1 {
        padding-left: 0;
        padding-right: 0;
    }

    .nav-tabs .nav-item1 i {
        margin-right: 0;
        margin-left: 10px;
    }

    .nav-tabs .nav-submenu {
        border-radius: 3px 3px 0 0;
    }

    .nav-unread {
        right: auto;
        left: .4rem;
    }

    .nav-unread.badge {
        right: auto;
        left: 0;
    }

    .navtab-wizard li:first-of-type a {
        border-left: inherit !important;
        border-right: none !important;
    }

    .navtab-wizard li:first-of-type a:hover,
    .navtab-wizard li:first-of-type a:focus,
    .navtab-wizard li:first-of-type a:active {
        border-left: inherit !important;
        border-right: none !important;
    }

    .navtab-wizard li:last-of-type a {
        border-right: inherit !important;
        border-left: none !important;
    }

    .navtab-wizard li:last-of-type a:hover,
    .navtab-wizard li:last-of-type a:focus,
    .navtab-wizard li:last-of-type a:active {
        border-right: inherit !important;
        border-left: none !important;
    }

    .nav-tabs .nav-item i {
        margin-right: 0;
        margin-right: 0;
        margin-left: .25rem;
        margin-left: 10px;
    }

    .nav-tabs .nav-submenu {
        border-radius: 3px 3px 0 0;
    }

    .pagination {
        padding-left: inherit;
        padding-right: 0;
    }

    .page-link {
        margin-left: 0;
        margin-right: -1px;
    }

    .page-item:first-child .page-link {
        margin-left: auto;
        margin-right: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .page-item:last-child .page-link {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .pagination-lg .page-item:first-child .page-link {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .pagination-lg .page-item:last-child .page-link {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .pagination-sm .page-link .page-subtitle {
        right: 0;
        left: 59rem;
    }

    .pagination-sm .page-link .page-options {
        margin-left: 0;
        margin-right: auto;
    }

    .pagination-sm .page-item:first-child .page-link {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
    }

    .pagination-sm .page-item:last-child .page-link {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    @media (max-width: 320px) {
        .fc-toolbar .fc-right {
            float: left;
        }
    }

    .page-total-text {
        margin-right: 0;
        margin-left: 1rem;
    }

    .more-less {
        float: left;
    }

    .panel-group1>.panel1:first-child .panel-heading1 {
        border-radius: 0 0 4px 4px;
    }

    .panel1:last-child .panel-body1 {
        border-radius: 4px 4px 0 0;
    }

    .panel1:last-child .panel-heading1 {
        border-radius: 4px 4px 0 0;
    }

    .panel-title a.accordion-toggle:before {
        padding: 0 0 0 10px;
        float: left;
    }

    .panel-title a.accordion-toggle.collapsed:before {
        padding: 0 0 0 10px;
        float: left;
    }

    .panel-heading1 a.collapsed:before {
        right: auto;
        left: 10px;
    }

    .panel-heading1 a:before {
        right: auto;
        left: 10px;
    }

    #bs-collapse .panel-heading1 a:after {
        right: auto;
        left: 5px;
    }

    #accordion .panel-heading1 a:before {
        right: auto;
        left: 5px;
    }

    .progress-bar-indeterminate:after {
        left: auto;
        right: 0;
    }

    .progress-bar-indeterminate:before {
        left: auto;
        right: 0;
    }

    .table-calendar-link:before {
        right: .25rem;
        left: auto;
    }

    .table-header:after {
        margin-left: auto;
        margin-right: .5rem;
    }

    table.table-bordered.dataTable th:first-child,
    table.table-bordered.dataTable th:first-child,
    table.table-bordered.dataTable td:first-child,
    table.table-bordered.dataTable td:first-child {
        border-left-width: inherit !important;
        border-right-width: 0 !important;
    }

    .tag-addon {
        margin: 0 .5rem 0 -.5rem;
    }

    .tag-addon:last-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
    }

    .tag-avatar {
        border-radius: 0 3px 3px 0;
        margin: 0 -.5rem 0 .5rem;
    }

    .tags>.tag:not(:last-child) {
        margin-right: auto;
        margin-left: .5rem;
    }

    .tooltip {
        text-align: right;
    }

    .tooltip.bs-tether-element-attached-left,
    .tooltip.tooltip-end {
        margin-left: 0;
        margin-right: 3px;
    }

    .tooltip.bs-tether-element-attached-bottom .tooltip-inner::before,
    .tooltip.tooltip-top .tooltip-inner::before {
        left: auto;
        right: 50%;
        margin-left: 0;
        margin-right: -5px;
    }

    .tooltip.bs-tether-element-attached-left .tooltip-inner::before,
    .tooltip.tooltip-end .tooltip-inner::before {
        left: auto;
        right: 0;
        border-width: 5px 0 5px 5px;
        border-right-color: transparent;
        border-left-color: $black;
    }

    .tooltip.bs-tether-element-attached-top .tooltip-inner::before,
    .tooltip.tooltip-bottom .tooltip-inner::before {
        left: auto;
        right: 50%;
        margin-left: auto;
        margin-right: -5px;
    }

    .tooltip.bs-tether-element-attached-right,
    .tooltip.tooltip-start {
        margin-left: auto;
        margin-right: -3px;
    }

    .tooltip.bs-tether-element-attached-right .tooltip-inner::before,
    .tooltip.tooltip-start .tooltip-inner::before {
        right: auto;
        left: 0;
        border-width: 5px 5px 5px 0;
        border-left-color: transparent;
        border-right-color: $black;
    }

    .bs-tooltip-end .popover-arrow,
    .bs-tooltip-auto[data-popper-placement^="right"] .popover-arrow {
        left: auto;
        right: 0;
    }

    .bs-tooltip-end .popover-arrow::before,
    .bs-tooltip-auto[data-popper-placement^="right"] .popover-arrow::before {
        right: auto;
        left: 0;
        border-width: 0.4rem 0 0.4rem 0.4rem;
        border-right-color: transparent;
        border-left-color: $black;
    }

    .bs-tooltip-start .popover-arrow,
    .bs-tooltip-auto[data-popper-placement^="left"] .popover-arrow {
        right: auto;
        left: 0;
    }

    .bs-tooltip-start .popover-arrow::before,
    .bs-tooltip-auto[data-popper-placement^="left"] .popover-arrow::before {
        left: auto;
        right: 0;
        border-width: 0.4rem 0.4rem 0.4rem 0;
        border-left-color: transparent;
        border-right-color: $black;
    }

    .popover {
        left: auto;
        right: 0;
        text-align: right;
    }

    .bs-popover-end,
    .bs-popover-auto[data-popper-placement^="right"] {
        margin-left: auto;
        margin-right: 0.5rem;
    }

    .bs-popover-end .arrow,
    .bs-popover-auto[data-popper-placement^="right"] .arrow {
        left: 0;
        right: calc((0.5rem + 1px) * -1);
    }

    .bs-popover-end .arrow::before,
    .bs-popover-auto[data-popper-placement^="right"] .arrow::before,
    .bs-popover-end .arrow::after,
    .bs-popover-auto[data-popper-placement^="right"] .arrow::after {
        border-width: 0.25rem 0 0.25rem 0.5rem;
    }

    .bs-popover-end .arrow::before,
    .bs-popover-auto[data-popper-placement^="right"] .arrow::before {
        left: auto;
        right: 0;
        border-right-color: transparent;
        border-left-color: #dee3eb;
    }

    .bs-popover-end .arrow::after,
    .bs-popover-auto[data-popper-placement^="right"] .arrow::after {
        left: auto;
        right: 1px;
        border-right-color: transparent;
        border-left-color: $white;
    }

    .bs-popover-bottom .popover-header::before,
    .bs-popover-auto[data-popper-placement^="bottom"] .popover-header::before {
        left: auto;
        right: 50%;
        margin-left: auto;
        margin-right: -0.25rem;
    }

    .bs-popover-start,
    .bs-popover-auto[data-popper-placement^="left"] {
        margin-right: auto;
        margin-left: 0.5rem;
    }

    .bs-popover-start .arrow,
    .bs-popover-auto[data-popper-placement^="left"] .arrow {
        right: 0;
        left: calc((0.5rem + 1px) * -1);
    }

    .bs-popover-start .arrow::before,
    .bs-popover-auto[data-popper-placement^="left"] .arrow::before,
    .bs-popover-start .arrow::after,
    .bs-popover-auto[data-popper-placement^="left"] .arrow::after {
        border-width: 0.25rem 0.5rem 0.25rem 0;
    }

    .bs-popover-start .arrow::before,
    .bs-popover-auto[data-popper-placement^="left"] .arrow::before {
        right: auto;
        left: 0;
        border-left-color: transparent;
        border-right-color: #dee3eb;
    }

    .bs-popover-start .arrow::after,
    .bs-popover-auto[data-popper-placement^="left"] .arrow::after {
        right: 0;
        left: 1px;
        border-left-color: transparent;
        border-right-color: $white;
    }

    .popover .arrow {
        margin-left: 0;
        margin-right: calc(.25rem + -5px);
    }

    .popover-static-demo .popover .arrow {
        margin-left: 0;
        margin-right: calc(.25rem + 122px);
    }

    .bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
    .bs-popover-start>.popover-arrow::after {
        border-left-color: transparent !important;
        border-right-color: $white  !important;
    }

    .bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
    .bs-popover-end>.popover-arrow::after {
        border-right-color: transparent !important;
        border-left-color: $white  !important;
    }

    @media (max-width: 767px) {
        #count-down .clock-presenter {
            float: right;
        }

        #count-down .hours_dash {
            border-right: inherit;
            border-left: none;
        }
    }

    @media (max-width: 767px) {
        #count-down1 .clock-presenter {
            float: right;
        }

        #count-down1 .hours_dash {
            border-right: inherit;
            border-left: none;
        }
    }

    .notifyimg {
        float: right;
        margin-right: inherit;
        margin-left: 10px;
    }

    ul.labels-info li a i {
        padding-left: auto;
        padding-left: 10px;
    }

    .embed-responsive .embed-responsive-item,
    .embed-responsive iframe,
    .embed-responsive embed,
    .embed-responsive object,
    .embed-responsive video {
        left: auto;
        right: 0;
    }

    blockquote {
        padding-left: inherit;
        padding-right: 2rem;
        border-left: 0;
        border-right: 3px solid rgba(227, 237, 252, 0.5);
    }

    .blockquote-reverse {
        padding-right: 0;
        padding-left: 15px;
        text-align: left;
        border-left: 3px solid rgba(227, 237, 252, 0.5);
        border-right: 0;
    }

    blockquote.float-right {
        padding-right: 0;
        padding-left: 15px;
        text-align: left;
        border-left: 3px solid rgba(227, 237, 252, 0.5);
        border-right: 0;
    }

    blockquote cite {
        text-align: left;
    }

    @media (max-width: 767px) {
        .shop-dec .col-md-6.pe-0 {
            padding-right: 0 !important;
            padding-left: 0.75rem !important;
        }

        .shop-dec .col-md-6.ps-0 {
            padding-left: 0 !important;
            padding-right: 0.75rem !important;
        }
    }

    ul li .legend-dots {
        margin-right: 0;
        margin-left: .5rem;
    }

    .status-icon {
        margin-right: auto;
        margin-left: .375rem;
    }

    .example {
        border-radius: 0 0 3px 3px;
    }

    .colorinput-color:before {
        left: 0;
        right: .25rem;
    }

    #back-to-top {
        right: auto;
        left: 20px;
    }

    .richText .richText-toolbar ul li a {
        border-right: 0;
        border-left: #e8ebf3 solid 1px;
    }

    .video-list-thumbs .duration {
        left: 0;
        right: 12px
    }

    @media (max-width: 991px) {
        .product-singleinfo .border-start {
            border-left: 1px !important;
            border-right: 0 !important;
        }

        .support .border-end {
            border-right: 1px !important;
            border-left: 0 !important;
        }

        .wideget-user-info .wideget-user-warap .wideget-user-warap-r {
            margin-left: auto !important;
            margin-right: 5rem !important;
        }

        .wideget-user-desc .user-wrap {
            margin-left: auto !important;
            margin-right: 10px !important;
        }

        .product-singleinfo .product-item2-align dd {
            margin-left: auto !important;
            margin-right: 0 !important;
        }

        .product-5-desc a {
            float: left;
        }

        .get-contact a:last-child {
            margin-right: auto;
            margin-left: 0;
        }

        .header-nav .header-search-logo .header-logo .header-brand-img {
            margin-right: 0;
            margin-left: .5rem;
        }

        .product-item2-desc .label-rating {
            margin-right: 0 !important;
            margin-left: 4px !important;
        }

        .card-blogs .card-item-desc.p-0 {
            margin-left: 0;
            margin-right: 15px;
        }

        .license-card-price.text-center {
            text-align: right !important;
        }

        .header-main .get-contact a {
            border-left: 1px !important;
            border-right: 0 !important;
            padding-left: auto !important;
            padding-right: 0 !important;
        }

        .header-main .social-icons li:last-child {
            margin-right: auto;
            margin-left: 0;
        }

        .top-bar .top-bar-left .contact {
            border-left: 1px !important;
            border-right: 0 !important;
            margin-left: auto !important;
            margin-right: 0 !important;
            padding-left: auto !important;
            padding-right: 0 !important;
        }

        .item-user-icons {
            margin-left: auto;
            margin-right: 10px;
        }

        .carousel-control-next {
            right: auto !important;
            left: 0 !important;
        }

        .carousel-control-prev {
            left: auto !important;
            right: 0 !important;
        }

        .userprof-tab .media-body .card-item-desc {
            margin-left: auto !important;
            margin-right: 0 !important;
        }

        .settings-tab .tabs-menu li a {
            border-right: 1px !important;
            border-left: 0 !important;
        }
    }

    @media (max-width: 568px) {
        .header-main .top-bar-right .custom {
            text-align: left;
            float: left;
        }

        .top-bar .top-bar-left .socials li {
            float: right !important;
        }
    }

    @media (min-width: 481px) and (max-width: 991px) {
        .item-card:hover .item-card-btn {
            left: 0;
            right: 42%;
        }

        .userprof-tab .media-body .card-item-desc {
            margin-left: auto !important;
            margin-right: 0 !important;
        }
    }

    @media (min-width: 992px) and (max-width: 1024px) {
        .header-main .get-contact a {
            border-left: 1px !important;
            border-right: 0 !important;
            padding-left: auto !important;
            padding-right: 0 !important;
        }

        .header-main .social-icons li:last-child {
            margin-right: auto;
            margin-left: 0;
        }

        .header-search .header-icons .header-icons-link {
            float: left;
        }

        .top-bar .top-bar-left .contact {
            border-left: 1px !important;
            border-right: 0 !important;
            margin-left: auto !important;
            margin-right: 0 !important;
            padding-left: auto !important;
            padding-right: 0 !important;
        }

        .top-bar .top-bar-left .contact li:first-child {
            margin-right: auto !important;
            margin-left: 1.5rem !important;
        }
    }

    @media (max-width: 480px) {
        .support .support-service i {
            margin-right: auto !important;
            margin-left: 0 !important;
        }

        .item-det ul li {
            margin-right: auto !important;
            margin-left: 0.5rem !important;
        }

        .userprof-tab .media-body .card-item-desc {
            margin-left: auto !important;
            margin-right: 0 !important;
        }
    }

    .social li {
        margin-right: 0;
        margin-left: 15px;
        float: left;
    }

    .social-icons li {
        margin-right: 0;
        margin-left: 15px;
    }

    .product-tags a {
        float: right;
        margin-right: auto;
        margin-left: 5px;
    }

    .support-service i {
        float: right;
        margin-right: auto;
        margin-left: 15px;
    }

    .top-bar .top-bar-right {
        float: left;
    }

    .top-bar .top-bar-left .socials li {
        float: right;
    }

    .usertab-list li {
        float: right;

        a {
            span {
                float: right;
            }
        }
    }

    .product-item span {
        right: 15px;
        left: auto;
    }

    .product-item-wrap .product-item-price .oldprice {
        margin-left: auto;
        margin-right: 5px;
    }

    .product-item2-rating {
        margin-right: auto;
        margin-left: 10px;
    }

    .product-item2-desc .label-rating {
        margin-right: auto;
        margin-left: 10px;
    }

    .product-gallery-data .product-gallery-data1 dt {
        float: right;
    }

    .product-gallery-data .product-gallery-data1 dd {
        margin-left: auto;
        margin-right: 2rem;
    }

    .product-gallery-rating {
        margin-right: auto;
        margin-left: 10px;
    }

    .product-gallery-data .label-rating {
        margin-right: auto;
        margin-left: 10px;
    }

    .product-gallery-data .product-gallery-size .product-gallery-checks label {
        margin-left: auto;
        margin-right: 10px;
    }

    .banner1 .carousel-item:before {
        right: auto;
        left: 0;
    }

    .banner1 .carousel-control .glyphicon-chevron-left,
    .banner1 .carousel-control .icon-prev {
        left: auto;
        right: 0;
    }

    .banner1 .carousel-control .glyphicon-chevron-right,
    .banner1 .carousel-control .icon-next {
        right: auto;
        left: 0;
    }

    .cat-item a {
        left: auto;
        right: 0;
    }

    #thumbcarousel .carousel-control-prev {
        left: auto;
        right: 0;
    }

    #thumbcarousel .carousel-control-next {
        right: auto;
        left: 0;
    }

    @media (max-width: 991px) {
        .banner-1 .search-background .form1 {
            border-left: 0 !important;
            border-right: 1px solid $border  !important;
        }

        .item-search-tabs a.btn {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
            border-top-right-radius: 3px !important;
            border-bottom-right-radius: 3px !important;
            right: auto !important;
            left: 0 !important;
        }

        .banner-1 .search-background .btn-lg {
            right: auto !important;
            left: 0 !important;
        }
    }

    .banner-1 .search-background .form1 {
        border-left: 0 !important;
        border-right: 1px solid $border  !important;
    }

    .banner-1 .search-background .btn-lg {
        right: auto;
        left: -1px;
    }

    .support-list li i {
        margin-right: inherit;
        margin-left: 0.7rem;
    }

    .profile-pic-img span {
        right: auto;
        left: -1px;
    }

    .my-dash.app-sidebar .mCSB_draggerContainer {
        right: auto;
        left: -11px;
    }

    .my-dash .side-menu {
        .slide {
            .side-menu__item {
                .angle {
                    &.fa-angle-right:before {
                        content: "\f104";
                    }
                }
            }

            .sub-slide {
                .side-menu__item {
                    .sub-angle {
                        &.fa-angle-right:before {
                            content: "\f104";
                        }
                    }
                }
            }
        }
    }

    .my-dash.app-sidebar .side-menu__item.active {
        border-right: 0;
        border-left: 2px solid $primary;
    }

    .my-dash .slide-menu li ul a {
        padding: 10px 65px 10px 10px;
    }

    .my-dash .app-sidebar-footer a {
        float: right;
        border-right: 0;
        border-left: 1px solid #e9ecf3;
    }

    .my-dash .slide ul ul a {
        padding: 10px 76px 10px 10px !important;
    }

    .my-dash .sub-slide .sub-angle {
        margin-right: auto;
        margin-left: 10px;
    }

    .my-dash .slide-item {
        padding: 10px 57px 10px 10px;
    }

    .my-dash .slide-item .icon {
        margin-right: auto;
        margin-left: 5px;
    }

    .settings-tab .tabs-menu li a {
        border-right: 0;
        border-left: 1px solid rgba(167, 180, 201, 0.2);
    }

    .ads-tabs .tabs-menus ul li {
        margin-right: 0;
        margin-left: 1rem;
    }

    .ads-tabs .tabs-menus ul li:last-child {
        margin-right: 0;
        margin-left: 0;
    }

    .slider .owl-nav .owl-prev {
        left: auto;
        right: 15px;
    }

    .slider .owl-nav .owl-next {
        right: auto;
        left: 15px;
    }

    .owl-nav .owl-prev {
        left: -1.5em;
        right: -25px;
    }

    .owl-nav .owl-next {
        right: auto;
        left: -25px;
    }

    @media (max-width: 480px) {
        .owl-nav .owl-prev {
            left: auto;
            right: -10px;
        }

        .owl-nav .owl-next {
            right: auto;
            left: -10px;
        }
    }

    .profile-pic .d-md-flex {
        text-align: right;
    }

    @media (max-width: 480px) {
        .ads-tabs .tabs-menus ul li {
            margin-right: auto;
            margin-left: 0;
        }

        .zoom-container .arrow-ribbon2:before {
            right: auto;
            left: -17px;
        }
    }

    @media (min-width: 561px) and (max-width: 767px) {
        .navtab-wizard.nav-tabs .nav-link {
            border-left: 1px;
            border-right: 0;
        }
    }

    .register-right .nav-tabs .nav-item {
        margin: 0 0 0 5px;
    }

    #homeVideo button.btn.btn-default {
        right: auto;
        left: 5px;
    }

    button#ihavecookiesBtn {
        margin-left: auto !important;
        margin-right: 0px !important;
    }

    #g.pe-cookie-message {
        right: auto;
        left: 0px;
        margin-right: auto;
        margin-left: 30px;
    }

    button#g.pe-cookie-advanced {
        margin-right: auto;
        margin-left: 4px;
    }

    @media (max-width: 390px) {
        button#g.pe-cookie-advanced {
            margin-left: auto !important;
            margin-right: 0 !important;
        }
    }

    @media (max-width: 480px) {
        #g.pe-cookie-message {
            right: auto;
            left: 0;
        }
    }

    #g.pe-cookie-message input[type="checkbox"] {
        margin-right: auto;
        margin-left: 5px;
    }

    button#g.pe-cookie-advanced {
        margin-left: auto;
        margin-right: 4px;
    }

    #gdpr-cookie-message {
        right: auto;
        left: 0px;
        margin-right: auto;
        margin-left: 30px;
    }

    @media (min-width: 992px) {
        .search3 .form-group:first-child .select2-container .select2-selection--single {
            border-radius: 0 3px 3px 0 !important;
        }
    }

    .modal-header .close.btn {
        float: left;
        right: auto;
        left: 15px;
    }

    .Realestate-content .form .form-group .select2-container--default .select2-selection--single {
        border-right: 1px !important;
    }

    .banner-1.bg-background2 .search-background .select2-container--default .select2-selection--single {
        border-right: 1px solid #e8ebf3 !important;
        border-bottom: 0 !important;
    }

    .banner-1.bg-background2.index .search-background .select2-container--default .select2-selection--single {
        border-left: 0 !important;
    }

    .item-search-tabs.travel-content .form-control {
        border-right: 1px solid #e8ebf3 !important;
        border-left: 0 !important;
    }

    .banner-2.bg-background2.index .item-search-tabs .tab-content .form-group:not(:last-child) .select2-container--default .select2-selection--single {
        border-left: 1px solid #e8ebf3 !important;
        border-right: 0 !important;
    }

    @media screen and (max-width: 480px) {
        #index1 .card-body a.me-4.float-end {
            margin-right: auto !important;
            margin-left: 0 !important;
        }
    }

    .header-2 .banner-1 .search-background .btn-lg {
        right: auto;
        left: 0;
    }

    .header-2 .item-search-tabs a.btn {
        right: auto;
        left: 0;
    }

    .search3 .select2-container--default .select2-selection--single {
        border-right: 1px solid #e8ebf3 !important;
        border-left: 0px !important;
    }

    .card-blog-overlay2 .form-control {
        border-right: 1px solid #e8ebf3 !important;
        border-left: 0px !important;
    }

    .example+.highlight {
        border-radius: 3px 3px 0 0;
    }

    .imagecheck-figure:before {
        left: auto;
        right: .25rem;
    }

    .demo-gallery>ul>li {
        float: right;
    }

    .demo-gallery>ul>li a {
        float: right;
    }

    .demo-gallery>ul>li a .demo-gallery-poster>img {
        left: auto;
        right: 50%;
        margin-left: auto;
        margin-right: -10px;
    }

    .demo-gallery .justified-gallery>a .demo-gallery-poster>img {
        left: auto;
        right: 50%;
        margin-left: auto;
        margin-right: -10px;
    }

    .demo-gallery .video .demo-gallery-poster img {
        margin-left: auto;
        margin-right: -24px;
    }

    .gallery .mask,
    .gallery .content {
        left: auto;
        right: 0;
    }

    .label.arrowed,
    .label.arrowed-in {
        margin-left: auto;
        margin-right: 9px;
    }

    .label.arrowed:before,
    .label.arrowed-in:before {
        left: auto;
        right: -14px;
        border-right-color: transparent;
        border-left-color: #abbac3;
    }

    .label-primary.arrowed:before {
        border-left-color: $primary;
    }

    .label.arrowed-right,
    .label.arrowed-in-right {
        margin-right: auto;
        margin-left: 9px;
        border-radius: 2px 0 0 2px;
    }

    .label-info.arrowed:before {
        border-right-color: transparent;
        border-left-color: $info;
    }

    .label-danger.arrowed:before {
        border-right-color: transparent;
        border-left-color: $danger;
    }

    .label-success.arrowed:before {
        border-right-color: transparent;
        border-left-color: $success;
    }

    .label-warning.arrowed:before {
        border-right-color: transparent;
        border-left-color: $yellow;
    }

    .list-unstyled,
    .list-inline {
        padding-left: 0;
        padding-right: 0;
    }

    .list-inline-item:not(:last-child) {
        margin-right: auto;
        margin-left: 0.5rem;
    }

    .list-style-1 ul {
        padding-right: 30px;
        padding-left: inherit;
    }

    .list-style2 {
        padding-left: inherit;
        padding-right: inherit;
    }

    .list-style2 ul {
        padding-right: 30px;
        padding-left: inherit;
    }

    .list-style3 {
        padding-left: inherit;
        padding-right: inherit;
    }

    .list-style3 ul {
        padding-right: 30px;
        padding-left: inherit;
    }

    .list-style4 {
        padding-left: inherit;
        padding-right: inherit;
    }

    .list-style4 ul {
        padding-right: 30px;
        padding-left: inherit;
    }

    .list-style5 {
        padding-left: inherit;
        padding-right: inherit;
    }

    .list-style5 ul {
        padding-right: 30px;
        padding-left: inherit;
    }

    .list-style6 {
        padding-left: inherit;
        padding-right: inherit;
    }

    .list-style6 ul {
        padding-right: 30px;
        padding-left: inherit;
    }

    .media-right,
    .media>.pull-right {
        padding-left: inherit;
        padding-right: 10px;
    }

    .media-left,
    .media>.pull-left {
        padding-left: auto;
        padding-left: 10px;
    }

    .media-left {
        padding-left: auto;
        padding-left: 10px;
    }

    .media>.pull-left {
        padding-left: 10px;
        padding-left: auto;
    }

    .navbar-brand {
        margin-right: auto;
        margin-left: 1rem;
    }

    .navbar-nav {
        padding-left: inherit;
        padding-right: 0;
    }

    .col-sm-4 .pricing-table .table td {
        text-align: right;
    }

    .item-card .cardbody {
        border-radius: .2rem .2rem 0 0;
    }

    .timeline:before {
        left: auto;
        right: 4px;
    }

    .timeline-item {
        padding-left: inherit;
        padding-right: 2rem;
    }

    .timeline-item:first-child:before,
    .timeline-item:last-child:before {
        left: .25rem;
        right: auto;
    }

    .timeline-badge {
        left: auto;
        right: 1px;
    }

    .timeline-time {
        margin-left: inherit;
        margin-right: auto;
    }

    .form-file-label::after {
        border-left: 0;
        border-right: 1px solid $primary;
    }

    @media (max-width: 480px) {
        .zoom-container .arrow-ribbon2:before {
            border-left: 0;
            border-right: 1px solid $primary;
        }
    }

    .arrow-ribbon.bg-primary:before {
        border-left: 0;
        border-right: 15px solid $primary;
    }

    .footer .social ul li {
        float: right;
    }

    .footer-main .social li {
        float: right;
        margin-right: auto;
        margin-left: 15px;
    }

    .footer-main .payments li {
        float: right;
        margin-right: auto;
        margin-left: 15px;
    }

    .app-header .header-brand {
        margin-right: auto;
        margin-left: 0;
    }

    .app-header .header-brand .header-brand-img {
        margin-right: auto;
        margin-left: 0;
    }

    .app-header1 .header-brand {
        margin-right: inherit;
        margin-left: 0;
    }

    .app-header1 .header-brand .header-brand-img {
        margin-right: auto;
        margin-left: 0;
    }

    .header-brand-img {
        margin-right: auto;
        margin-left: .5rem;
    }

    .header-btn.has-new:before {
        right: auto;
        left: 4px;
    }

    .header-toggler-icon {
        left: auto;
        right: 50%;
        margin: -2px -.5rem 0 0;
    }

    .header .nav-link .badge,
    .header .nav-item .badge {
        right: auto;
        left: -1px;
    }

    .header-main .social-icons {
        float: left;
    }

    .header-main .social-icons li {
        margin-right: auto;
        margin-left: 35px;
    }

    .header-search .header-icons .header-icons-link li {
        margin-right: auto;
        margin-left: 35px;
    }

    .header-search .header-icons .header-icons-link1 .main-badge1 {
        right: auto;
        left: -15px;
    }

    .header-search .header-inputs .input-group-text.searchicon {
        right: auto;
        left: 25px;
    }

    .header-search .header-search-logo {
        margin-right: auto;
        margin-left: 1rem;
    }

    .header-main .top-bar .contact {
        padding-left: 0;
        padding-right: 15px;
    }

    .header-main .top-bar-right .custom li {
        margin-right: auto;
        margin-left: 15px;
    }

    .header-main .top-bar-right .custom li:last-child {
        margin-right: auto;
        margin-left: 0;
    }

    .header-main .post-btn {
        float: left;
        margin-left: inherit;
        margin-right: auto;
    }

    .aside {
        right: auto;
        left: 0;
        border-left: 0;
        border-right: 1px solid $border;
    }


    .icon-absolute {
        right: auto;
        left: 10px;
    }

    .icons-list {
        margin: 0 0 -1px -1px;
    }

    .icon-card li {
        float: right;
    }

    .item-card {
        .cardprice {
            right: auto;
            left: 15px;
        }

        .item-card-desc:before {
            left: 0;
            right: auto;
        }
    }

    .item-card-desc a {
        left: auto;
        right: 0;
    }

    .item-card .item-card-btn {
        left: auto;
        right: 34%;

        a {

            &:after,
            &:before {
                left: auto;
                right: 50%;
            }
        }
    }

    .item-card2-icons {
        right: auto;
        left: 15px;
    }

    .item-list .list-group-item i {
        margin-right: auto;
        margin-left: 5px;
    }

    .item-card4-img a {
        left: auto;
        right: 30px;
    }

    .item-card5-img a {
        right: auto;
        left: 12px;
    }

    .item-search-menu ul li {

        .active,
        a {
            border-radius: 3px 3px 0 0;
        }
    }

    .item-search-tabs {
        .tab-content {
            margin: 1px 0 0 5px;
        }

        .form-control.border {
            border-top-right-radius: inherit;
            border-bottom-right-radius: inherit;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }

        a.btn {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            right: auto;
            left: -2px;
        }
    }

    .item-all-cat .item-all-card a {
        left: auto;
        right: 0;
    }

    .item-card7-imgs .bdir {
        left: auto;
        right: 12px;
    }

    .item-card7-overlaytext {
        a {
            left: auto;
            right: 12px;
        }

        h4 {
            right: auto;
            left: 12px;
        }
    }

    .item-card7-imgs:before,
    .item-card2-img:before {
        right: auto;
        left: 0;
    }

    .Marketplace .item-card7-overlaytext h4 {
        right: auto;
        left: 5px;
    }

    .item-card7-imgs a,
    .item-card9-img .item-card9-imgs a,
    .item-card2-img a {
        left: auto;
        right: 0;
    }

    .item-card7-desc ul li {
        margin-right: 0;
        margin-left: 1rem;

        &:last-child {
            margin-right: inherit;
            margin-left: 0;
        }
    }

    .item1-card .item1-card-info .item1-card-icons li {
        margin-right: auto;
        margin-left: 15px;
    }

    .item-video i {
        margin-left: auto;
        margin-right: 5px;
    }

    .item2-gl-nav {
        text-align: left;

        .item2-gl-menu {
            margin-right: auto;
            margin-left: 10px;
        }
    }

    .item-card9-icons {
        right: auto;
        left: 15px;
    }

    .item-card9-imgs a {
        left: auto;
        right: 0;
    }

    .item-card9-desc ul li {
        float: right;
    }

    .item3-medias .item3-lists li {
        margin-right: inherit;
        margin-left: 5px;

        a {
            padding: 5px 0 5px 4px;
        }

        i {
            margin-right: auto;
            margin-left: 5px;
        }
    }

    .item-card2-desc ul li {
        margin-right: 0;
        margin-left: 1.5rem;

        &:last-child {
            margin-right: inherit;
            margin-left: 0;
        }
    }

    .item7-card-desc a:first-child {
        margin-right: 0;
        margin-left: 1rem;
    }

    .item7-card-img a {
        left: auto;
        right: 0;
    }

    .item-cards7-ic li {
        float: right;
        margin-right: auto !important;
        margin-left: 0 !important;
    }

    .item7-card-text span {
        left: auto;
        right: 10px;
    }

    @media (max-width: 767px) {
        .item-user .profile-pic .d-md-flex .ms-4 {
            margin-left: auto !important;
            margin-right: 0 !important;
        }
    }

    .item-card7-img {
        &:before {
            right: auto;
            left: 0;
        }

        h4 {
            right: auto;
            left: 15px;
        }
    }

    #sidebar li a[aria-expanded="true"].active:before {
        left: auto;
        right: 0;
    }


    .checkbox {
        span {
            padding-left: inherit;
            padding-right: 15px;

            &:empty {
                float: right;
            }

            &:before,
            &:after {
                left: auto;
                right: 0;
            }
        }

        input[type='checkbox'] {
            margin: 0 0 0 5px;
        }
    }


    .custom-control {
        padding-left: inherit;
        padding-right: 1.5rem;
    }

    .custom-control-inline {
        margin-right: auto;
        margin-left: 1rem;
    }

    .custom-control-label {

        &::before,
        &::after {
            left: auto;
            right: 0;
        }
    }

    .form-select {
        padding: 0.5rem 0.75rem 0.5rem 1.75rem;

        &[multiple],
        &[size]:not([size="1"]) {
            padding-left: auto;
            padding-left: 0.75rem;
        }
    }

    div.dataTables_wrapper div.dataTables_filter input {
        margin-left: auto;
        margin-right: 0.5em;
    }

    .form-file-label::after {
        right: auto;
        left: 0;
        border-radius: 3px 0 0 3px;
    }

    .form-range {
        padding-left: inherit;
        padding-right: 0;

        &::-ms-fill-upper {
            margin-right: auto;
            margin-left: 15px;
        }
    }

    .form-switch-indicator:before {
        left: auto;
        right: 1px;
    }

    .form-switch-input:checked~.form-switch-indicator:before {
        left: auto;
        right: calc(1rem + 1px);
    }

    .form-switch-description {
        margin-left: auto;
        margin-right: .5rem;
    }

    .form-switch {
        padding-left: inherit;
        padding-right: 0;
    }

    .ui-datepicker {
        .ui-datepicker-header {
            .ui-datepicker-next:before {
                right: auto;
                left: 5px;
                content: '\f104';
            }

            .ui-datepicker-prev:before {
                left: auto;
                right: 5px;
                content: '\f105';
            }
        }

        .ui-datepicker-calendar td {
            text-align: left;

            &:last-child {
                border-right: 1px;
                border-left: 0;
            }
        }
    }

    .ui-datepicker-multi {
        .ui-datepicker-group {
            padding-right: 0;
            padding-left: 15px;
            float: right;

            .ui-datepicker-prev::before {
                left: auto;
                right: 10px;
            }

            .ui-datepicker-next::before {
                right: auto;
                left: 10px;
            }
        }

        .ui-datepicker-group-last {
            padding-right: 0;
            padding-left: 0;
        }
    }

    .jvectormap-zoomin,
    .jvectormap-zoomout,
    .jvectormap-goback {
        left: auto;
        right: 10px;
    }

    .jvectormap-legend-cnt-h,
    .jvectormap-legend-cnt-v {
        right: auto;
        left: 0;
    }

    .jvectormap-legend-cnt-h .jvectormap-legend {
        float: right;
        margin: 0 0 10px 10px;

        .jvectormap-legend-tick {
            float: right;
        }
    }

    .jvectormap-legend-cnt-v {
        .jvectormap-legend {
            margin: 10px 0 0 10px;
        }

        .jvectormap-legend-tick-text {
            padding-left: inherit;
            padding-right: 3px;
        }
    }

    .range output {
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
    }

    .select-country .select2-container .select2-selection--single .select2-selection__rendered {
        padding-left: inherit;
        padding-right: 3px;
    }

    .select2-container {
        .select2-selection--single .select2-selection__rendered {
            padding-left: 20px;
            padding-right: 8px;
        }

        .select2-selection--multiple .select2-selection__rendered {
            padding-left: 0;
            padding-right: 8px;
        }

        .select2-search--inline {
            float: right;
        }
    }

    .select2-container--default {
        .select2-selection--single {
            .select2-selection__clear {
                float: left;
            }

            .select2-selection__arrow {
                right: auto;
                left: 1px;

                b {
                    left: auto;
                    right: 50%;
                    margin-left: auto;
                    margin-right: -4px;
                }
            }
        }

        .select2-selection--multiple {
            .select2-selection__placeholder {
                float: right;
            }

            .select2-selection__clear {
                float: left;
                margin-right: auto;
                margin-left: 10px;
            }

            .select2-selection__choice {
                float: right;
                margin-right: auto;
                margin-left: 5px;
            }
        }

        .select2-results__option .select2-results__option {
            padding-left: inherit;
            padding-right: 1em;

            .select2-results__group {
                padding-left: inherit;
                padding-right: 0;
            }

            .select2-results__option {
                margin-left: auto;
                margin-right: -1em;
                padding-left: inherit;
                padding-right: 2em;

                .select2-results__option {
                    margin-left: auto;
                    margin-right: -2em;
                    padding-left: 0;
                    padding-right: 3em;

                    .select2-results__option {
                        margin-left: auto;
                        margin-right: -3em;
                        padding-left: 0;
                        padding-right: 4em;

                        .select2-results__option {
                            margin-left: auto;
                            margin-right: -4em;
                            padding-left: 0;
                            padding-right: 5em;

                            .select2-results__option {
                                margin-left: auto;
                                margin-right: -5em;
                                padding-left: 0;
                                padding-right: 6em;
                            }
                        }
                    }
                }
            }
        }
    }

    .select2-container--classic {
        .select2-selection--single {
            .select2-selection__clear {
                float: left;
                margin-right: auto;
                margin-left: 10px;
            }

            .select2-selection__arrow {
                border-left: 0;
                border-right: 1px solid #aaa;
                right: 0;
                left: 1px;

                b {
                    left: 0;
                    right: 50%;
                    margin-left: 0;
                    margin-right: -4px;
                }
            }
        }

        .select2-selection--multiple {
            .select2-selection__choice {
                float: right;
                margin-right: auto;
                margin-left: 5px;
            }

            .select2-selection__choice__remove {
                margin-right: auto;
                margin-left: 2px;
            }
        }
    }

    .selectgroup-item {
        +.selectgroup-item {
            margin-left: auto;
            margin-right: -1px;
        }

        &:not(:first-child) .selectgroup-button {
            border-top-left-radius: 3px;
            border-bottom-left-radius: 3px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:not(:last-child) .selectgroup-button {
            border-top-right-radius: 3px;
            border-bottom-right-radius: 3px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    .selectgroup-input {
        left: auto;
        right: 0;
    }

    .selectgroup-pills .selectgroup-item {
        margin-right: auto;
        margin-left: .5rem;
    }

    i {
        &.icon-datepicker-prev:before {
            content: '\e600';
        }

        &.icon-datepicker-next:before {
            content: '\e602';
        }
    }

    div.dataTables_wrapper div.dataTables_filter {
        text-align: left;

        input {
            margin-right: .5em;
            margin-left: auto;
        }
    }

    table {
        &.dataTable>thead {
            >tr> {

                th:not(.sorting_disabled),
                td:not(.sorting_disabled) {
                    padding-left: 30px;
                }
            }

            .sorting:before,
            .sorting_asc:before,
            .sorting_desc:before,
            .sorting_asc_disabled:before,
            .sorting_desc_disabled:before {
                left: 1em;
                right: auto;
            }

            .sorting:after,
            .sorting_asc:after,
            .sorting_desc:after,
            .sorting_asc_disabled:after,
            .sorting_desc_disabled:after {
                left: .5em;
                right: auto;
            }
        }

        &.table-bordered.dataTable {
            border-left-width: 0;
            border-right-width: 1px;

            th:first-child,
            td:first-child {
                border-right-width: 0px;
                border-left-width: 1px;
            }

            th:last-child,
            td:last-child {
                border-left-width: 1px;
            }
        }
    }

    div.table-responsive>div.dataTables_wrapper>div.row>div[class^=col-]:first-child {
        padding-right: 0;
        padding-left: inherit;
    }

    .dropdown-menu-end {
        --bs-position: start;
    }

    .dropdown-menu-start {
        --bs-position: end;
    }

    @media (min-width: 1024px) {
        .horizontalMenu>.horizontalMenu-list>li>.horizontal-megamenu .link-list:first-child {
            border: 0 !important;
        }
    }

    #myCarousel2 {
        .card {
            .card-footer {
                h5 {
                    i {
                        float: left;
                        margin-top: 6px;
                    }
                }
            }

            .item-card7-desc {
                .item-cards7-ic {
                    li {
                        a {
                            i {
                                float: right;
                                margin-top: 3px;
                            }
                        }
                    }

                }
            }
        }
    }

    #myCarousel1,
    #myCarousel5 {
        .card {
            .item-card2 {
                .item-card2-desc {
                    ul {
                        li {
                            a {
                                i {
                                    float: right;
                                    margin-top: 3px;
                                }
                            }
                        }
                    }
                }
            }

            .card-footer {
                .item-card2-footer {
                    .item-card2-footer-u {
                        .icons {
                            display: flex;

                            i {
                                float: right;
                                margin-top: 3px;
                            }

                            a {
                                i {
                                    float: left;
                                    margin-top: 6px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #myCarousel3 {
        .card {
            .card-body {
                .item-card7-text {
                    .icon-card {
                        li {
                            .icons {
                                i {
                                    float: right;
                                    margin-top: 3px;
                                }
                            }
                        }
                    }
                }
            }

            .card-footer {
                a {
                    display: inline-block;
                }
            }
        }
    }

    #myCarousel1 {
        .card {
            .card-body {
                .item-card7-text {
                    .icon-card {
                        li {
                            .icons {
                                i {
                                    float: right;
                                    margin-top: 3px;
                                }
                            }
                        }
                    }
                }
            }

            .card-footer {
                a {
                    display: inline-block;
                }
            }
        }
    }

    #feature-carousel {
        .card {
            .card-body {
                .item-card7-text {
                    .icon-card {
                        li {
                            .icons {
                                i {
                                    float: right;
                                    margin-top: 3px;
                                }
                            }
                        }
                    }
                }

                .item-card7-desc {
                    ul {
                        li {
                            .icons {
                                i {
                                    float: right;
                                    margin-top: 3px;
                                }
                            }
                        }

                    }
                }
            }

            .card-footer {
                .footerimg {
                    .footerimg-l {
                        h5 {
                            i {
                                float: left;
                                margin-top: 6px;
                            }
                        }

                        a {
                            i {
                                float: left;
                                margin-top: 6px;
                            }
                        }
                    }
                }
            }
        }

    }

    .panel {
        .panel-body {

            #index1,
            #index2,
            #index3,
            #index4 {
                .card {
                    .card-body {
                        .item-card7-desc {
                            ul {
                                li {
                                    .icons {
                                        i {
                                            float: right;
                                            margin-top: 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .card-footer {
                        a {
                            display: inline-block;
                        }
                    }
                }
            }
        }
    }

    #Latest-carousel {
        .card {
            .card-body {
                .item-card2 {
                    .item-card2-desc {
                        li {
                            .icons {
                                i {
                                    float: right;
                                    margin-top: 3px;
                                }
                            }
                        }
                    }
                }
            }

            .card-footer {
                .item-card2-footer {
                    .item-card2-footer-u {
                        .icons {
                            display: flex;

                            i {
                                float: right;
                                margin-top: 3px;
                            }

                            a {
                                i {
                                    float: left;
                                    margin-top: 6px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #container1 {
        .item-det.row {
            ul {
                li {
                    .icons {
                        i {
                            float: right;
                            margin-top: 3px;
                        }
                    }
                }
            }
        }
    }

    .business-item.Marketplace {
        .items-gallery {
            .items-blog-tab {
                .tab-pane {
                    .card {
                        .card-footer {
                            .footerimg {
                                .footerimg-l {
                                    a {
                                        i {
                                            float: left;
                                            margin-top: 6px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .reviews-categories {
        .widget-spec1 {
            li {
                a {
                    .fa-caret-right:before {
                        content: "\f0d9";
                    }
                }
            }
        }

        h3 {
            &.card-title {
                i {
                    float: right;
                }
            }
        }
    }

    .dataTables_wrapper .dataTables_length {
        float: right;
    }

    .dataTables_wrapper .dataTables_filter {
        float: left;
    }

    div.table-responsive>div.dataTables_wrapper>div.row>div[class^=col-]:last-child {
        padding-right: inherit;
        padding-left: 0;
    }

    .dataTables_wrapper .dataTables_info {
        float: right;
    }

    .dataTables_wrapper .dataTables_paginate {
        float: left;
    }

    .product-5 {
        .product-5-desc {
            h4 {
                del {
                    float: left;
                }
            }
        }
    }

    .item2-gl {
        .tab-content {
            .tab-pane {
                .card {
                    .card-body {
                        .item-card9 {
                            .item-card9-desc {
                                a {
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #profile-log-switch {
        .table-responsive {
            .table {
                &.table-borderless {
                    tbody {
                        tr {
                            td {
                                strong {
                                    float: right;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .table-responsive {
        .table {
            &.table-borderless {
                tbody {
                    tr {
                        td {
                            span {
                                &.font-weight-bold {
                                    float: right;
                                }

                                .fa-caret-right:before {
                                    content: "\f0d9";
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .item-det {
        ul {
            li {
                .icons {
                    i {
                        float: right;
                        margin-top: 5px;
                    }
                }
            }
        }
    }

    .item-card7-desc {
        ul {
            li {
                .icons {
                    i {
                        float: right;
                        margin-top: 3px;
                    }
                }

            }
        }
    }

    .card {
        .card-footer {
            .footerimg {
                .footerimg-l {
                    a {
                        &.time-title {
                            i {
                                float: left;
                                margin-top: 5px;
                            }
                        }
                    }
                }
            }
        }
    }

    .vertical-scroll {
        .item2 {
            .footerimg {
                .footerimg-l {
                    a {
                        &.time-title {
                            i {
                                float: left;
                                margin-top: 5px;
                            }
                        }
                    }
                }
            }
        }

        .item {
            .item-det {
                small {
                    display: flex;

                    i {
                        float: right;
                        margin-top: 3px;
                        margin-left: 5px;
                    }
                }
            }
        }
    }

    .tab-content {
        &.Marketplace {
            .item-card7-overlaytext a {
                left: auto;
                right: 25px;
            }
        }
    }

    #thumbcarousel {
        .carousel-control-prev {
            .fa-angle-left:before {
                content: "\f105";
            }
        }

        .carousel-control-next {
            .fa-angle-right:before {
                content: "\f104";
            }
        }
    }

    .form-control {
        &::-webkit-file-upload-button {
            float: left;
        }
    }

    [type=email],
    [type=number],
    [type=tel],
    [type=url] {
        direction: rtl;
    }

    ul {
        &.widget-spec {
            li {
                a {
                    i {
                        &.fa-caret-right:before {
                            content: "\f0d9";
                        }
                    }
                }
            }
        }
    }

    #myCarousel4,
    #myCarousel2,
    #myCarousel5,
    #myCarousel6 {
        .item {
            .card {
                .card-body {
                    .item-card7-text {
                        .icon-card {
                            li {
                                .icons {
                                    i {
                                        float: right;
                                        margin-top: 3px;
                                    }
                                }
                            }
                        }
                    }
                }

                .card-footer {
                    .footerimg {
                        .footerimg-l {
                            h5 {
                                i {
                                    float: left;
                                    margin-top: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #myCarousel2 {
        .item {
            .card {
                .card-body {
                    .item-card2 {
                        .item-card2-desc {
                            ul {
                                li {
                                    .icons {
                                        i {
                                            float: right;
                                            margin-top: 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

    }

    .item-user {
        .usertab-list {
            li {
                span.font-weight-semibold {
                    float: right;
                }
            }
        }

        div {
            i {
                &.fa-caret-right:before {
                    content: "\f0d9";
                }
            }
        }
    }

    .active.carousel-item-start,
    .carousel-item-prev:not(.carousel-item-end) {
        transform: translateX(100%) !important;
    }

    .active.carousel-item-end {
        transform: translateX(-100%) !important;
    }

    .carousel-item-next:not(.carousel-item-start) {
        transform: translateX(-100%) !important;
        transform: translateX(-100%) !important;
    }

    .small-container .show-small-img {
        float: right;
    }

    #myCarousel3,
    #myCarousel4 {
        .item {
            .card {
                .card-body {
                    .item-card2 {
                        .item-card2-desc {
                            ul {
                                li {
                                    .icons {
                                        i {
                                            float: right;
                                            margin-top: 3px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .card {
        .card-body {
            .item-det {
                div {
                    div {
                        .icons {
                            i {
                                float: right;
                                margin-top: 3px;
                            }
                        }
                    }
                }
            }
        }
    }

    .richText .richText-toolbar ul li {
        float: right;
    }

    .richText .richText-toolbar ul li a .richText-dropdown-outer .richText-dropdown .richText-dropdown-close {
        right: 0;
        left: -23px;
    }

    .richText .richText-toolbar ul li a .richText-dropdown-outer ul.richText-dropdown li.inline {
        float: right;
    }

    .richText .richText-editor {
        border-left: 0;
        border-right: $white solid 2px;
    }

    .richText .richText-editor:focus {
        border-left: 0;
        border-right: #2b88ff solid 2px;
    }

    .richText .richText-help {
        float: left;
    }

    .richText .richText-undo,
    .richText .richText-redo {
        float: right;
        border-right: 0;
        border-left: #EFEFEF solid 1px;
    }

    .richText .richText-list.list-rightclick {
        border-right: 0;
        border-left: #EFEFEF solid 1px;
    }

    .card-aside {
        .card-body {
            a {
                &.icon {
                    i {
                        &.fa-step-backward:before {
                            content: "\f051";
                        }

                        &.fa-step-forward:before {
                            content: "\f048";
                        }
                    }
                }
            }
        }
    }

    .typing_loader {
        left: 0;
        right: -12px;
    }

    form.convFormDynamic {

        textarea.userInputDynamic,
        input.userInputDynamic {
            float: right;
            margin-right: 10px;
            margin-left: 2.5%;
        }
    }

    div.conv-form-wrapper {
        &:before {
            left: inherit;
            right: 0;
        }

        div#messages div.message {
            float: left;

            &.to {
                float: right;
                border-top-left-radius: 20px;
                border-top-right-radius: 0;
            }

            &.from {
                border-top-right-radius: 20px;
                border-top-left-radius: 0;
            }
        }
    }

    form.convFormDynamic button.submit {
        float: left;
    }

    #growls-default {
        left: 10px;
        right: auto;
    }

    .growl .growl-close {
        float: left;
    }

    .ps__rail-y {
        left: 0;
        right: inherit !important;
    }


    .timeline {
        .time-show {
            margin-right: auto;
            margin-left: -75px;
        }

        &:before {
            left: auto;
            right: 50%;
        }

        .timeline-icon {
            left: auto;
            right: -54px;
        }
    }

    .timeline-item {
        .timeline-desk .popover-arrow {
            border-right: 0;
            border-left: 8px solid $white  !important;
            left: auto;
            right: -7px;
        }

        &.alt {
            .timeline-desk {
                .arrow-alt {
                    border-left: 0;
                    border-right: 8px solid $white  !important;
                    right: auto;
                    left: -7px;
                }

                .album {
                    float: left;

                    a {
                        float: left;
                        margin-left: 0;
                        margin-right: 5px;
                    }
                }
            }

            .timeline-icon {
                right: auto;
                left: -57px;
            }

            .panel {
                margin-right: 0;
                margin-left: 45px;
            }

            h4,
            p,
            .timeline-date {
                text-align: left;
            }
        }
    }

    .timeline-desk {
        .panel {
            margin-left: auto;
            margin-right: 45px;
        }

        .album a {
            float: right;
            margin-right: auto;
            margin-left: 5px;
        }
    }

    .timelineleft {
        &:before {
            left: auto;
            right: 31px;
        }

        >li {
            margin-right: auto;
            margin-left: 10px;

            > {
                .timelineleft-item {
                    margin-right: 60px;
                    margin-left: 15px;

                    >.time {
                        float: left;
                    }
                }

                .fa,
                .glyphicon,
                .ion {
                    left: auto;
                    right: 18px;
                }
            }
        }
    }

    i.icon-datepicker-prev:before {
        content: '\e600';
    }

    i.icon-datepicker-next:before {
        content: '\e602';
    }

    // .fc-theme-standard td:last-child,
    // .fc-theme-standard th:last-child {
    //     border-right: 1px solid #ecebf2;
    // }

    #mixed-error,
    #candlechart,
    #barlinechart,
    #multibar,
    #demodiv {
        div {
            text-align: right !important;
        }
    }

    .btn-group>.btn-pill.btn:not(:last-child):not(.dropdown-toggle) {
        border-top-right-radius: 10rem;
        border-bottom-right-radius: 10rem;
    }

    .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow,
    .bs-tooltip-end .tooltip-arrow {
        right: auto;
        left: 0;
    }

    .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
    .bs-tooltip-end .tooltip-arrow::before {
        left: auto;
        right: -1px;
        border-width: 0.4rem 0.4rem 0.4rem 0;
        border-left-color: transparent;
        border-right-color: #000;
    }

    .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow,
    .bs-tooltip-start .tooltip-arrow {
        right: 0;
        left: auto;
    }

    .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
    .bs-tooltip-start .tooltip-arrow::before {
        right: auto;
        left: -1px;
        border-width: 0.4rem 0 .4rem .4rem;
        border-right-color: transparent;
        border-left-color: #000;
    }

    .bs-popover-auto[data-popper-placement^=right]>.popover-arrow,
    .bs-popover-end>.popover-arrow {
        right: auto;
        left: calc(-0.5rem - 1px);
    }

    .bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,
    .bs-popover-end>.popover-arrow::before {
        right: auto;
        left: 0;
        border-width: .5rem .5rem .5rem 0;
        border-left-color: transparent;
        border-right-color: rgba(0, 0, 0, .25);
    }

    .bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,
    .bs-popover-end>.popover-arrow::after {
        right: 0;
        left: 1px;
        border-width: .5rem .5rem .5rem 0;
        border-left-color: transparent;
        border-right-color: #fff;
    }

    .bs-popover-auto[data-popper-placement^=left]>.popover-arrow,
    .bs-popover-start>.popover-arrow {
        left: auto;
        right: calc(-0.5rem - 1px);
    }

    .bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,
    .bs-popover-start>.popover-arrow::before {
        left: auto;
        right: 0;
        border-width: .5rem 0 .5rem .5rem;
        border-right-color: transparent;
        border-left-color: rgba(0, 0, 0, .25);
    }

    .bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,
    .bs-popover-start>.popover-arrow::after {
        right: 1px;
        left: 0;
        border-width: .5rem 0 .5rem .5rem;
        border-right-color: transparent;
        border-left-color: #fff;
    }

    .icons-list-item {
        border-right: 0;
        border-left: 1px solid #e8ebf3;
    }

    .pull-right {
        float: left;
    }

    .pull-left {
        float: right;
    }

    #gdpr-cookie-message input[type=checkbox] {
        margin-right: 0;
        margin-left: 5px;
    }

    .lg-toolbar .lg-icon {
        float: left;
    }

    .lg-outer .lg-dropdown {
        left: 0;
        right: auto;
        text-align: right;
    }

    .lg-outer .lg-dropdown .lg-icon {
        margin-right: auto;
        margin-left: 8px;
    }

    .dropdown-menu[data-popper-placement^=top],
    .dropdown-menu[data-popper-placement^=right],
    .dropdown-menu[data-popper-placement^=bottom],
    .dropdown-menu[data-popper-placement^=left] {
        right: auto !important;
        left: 0 !important;
    }

    .ui-datepicker .ui-datepicker-header .ui-datepicker-next::before,
    .ui-datepicker .ui-datepicker-header .ui-datepicker-prev::before {
        transform: rotate(180deg);
    }

    .item2-gl-nav {
        .select2-container {
            text-align: right !important;
        }
    }

    .lg-outer .lg-dropdown:after {
        left: 16px;
        right: auto !important;
    }

    .tab_wrapper .content_wrapper .accordian_header .arrow {
        float: left;
    }

    .ui-datepicker .ui-datepicker-prev {
        right: 2px;
        left: auto;
        transform: rotate(180deg);
    }

    .ui-datepicker .ui-datepicker-next {
        right: auto;
        left: 2px;
        transform: rotate(180deg);
    }

    .about-img2 .pe-0{
        padding-left: 0 !important;
        padding-right: 0.75rem !important;
    }

    .about-img2 .ps-0{
        padding-left: 0.75rem !important;
        padding-right: 0 !important;
    }
}

@media (max-width: 991px) {
    .rtl {
        .bg-white.header-2 {
            .header-text1 {
                .search-background {
                    .border-end-0 {
                        border-left: 1px solid $border  !important;
                    }
                }
            }
        }
    }
}


/*---- RTL Styles -------*/