.input-icon .form-group.is-empty {
	.form-control {
		margin-bottom: 0;
	}
	margin-bottom: 0;
}

.form-control {
	display: block;
	width: 100%;
	padding: 0.375rem 0.75rem;
	font-size: 0.9375rem;
	line-height: 1.6;
	color: $color;
	height: 39px;
	background-color: $white;
	background-clip: padding-box;
	border: 1px solid $border;
	border-radius: 3px;
	-webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
	&.border-white {
		border: 1px solid rgba(167, 180, 201, 0.7) !important;
	}
	&::-ms-expand {
		background-color: transparent;
		border: 0;
	}
	&:focus {
		color: #6f6e6e;
		background-color: $white;
		border-color: #e8ebf3;
		outline: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	&::-webkit-input-placeholder,
	&::-moz-placeholder,
	&:-ms-input-placeholder,
	&::-ms-input-placeholder {
		color: #a7b4c9;
		opacity: 1;
	}
	&:disabled,
	&[readonly] {
		background-color: #f9faff;
		opacity: 1;
	}
}

@media screen and (prefers-reduced-motion: reduce) {
	.form-control.border-white .form-control {
		-webkit-transition: none;
		transition: none;
	}
}

.form-control-file,
.form-control-range {
	display: block;
	width: 100%;
}

.col-form-label {
	padding-top: calc(0.375rem + 1px);
	padding-bottom: calc(0.375rem + 1px);
	margin-bottom: 0;
	font-size: inherit;
	line-height: 1.6;
}

.col-form-label-lg {
	padding-top: calc(0.5rem + 1px);
	padding-bottom: calc(0.5rem + 1px);
	font-size: 1.125rem;
	line-height: 1.44444444;
}

.col-form-label-sm {
	padding-top: calc(0.25rem + 1px);
	padding-bottom: calc(0.25rem + 1px);
	font-size: 0.875rem;
	line-height: 1.14285714;
}

.form-control-plaintext {
	display: block;
	width: 100%;
	padding-top: 0.375rem;
	padding-bottom: 0.375rem;
	margin-bottom: 0;
	line-height: 1.6;
	color: #3d4e67;
	background-color: transparent;
	border: solid transparent;
	border-width: 1px 0;
	&.form-control-sm {
		padding-right: 0;
		padding-left: 0;
	}
}

.input-group-sm> {
	.form-control-plaintext.form-control,
	.input-group-text>.form-control-plaintext.input-group-text,
	.input-group-text>.form-control-plaintext.input-group-text,
	.input-group-text>.form-control-plaintext.btn,
	.input-group-text>.form-control-plaintext.btn {
		padding-right: 0;
		padding-left: 0;
	}
}

.form-control-plaintext.form-control-lg {
	padding-right: 0;
	padding-left: 0;
}

.input-group-lg> {
	.form-control-plaintext.form-control,
	.input-group-text>.form-control-plaintext.input-group-text,
	.input-group-text>.form-control-plaintext.input-group-text,
	.input-group-text>.form-control-plaintext.btn,
	.input-group-text>.form-control-plaintext.btn {
		padding-right: 0;
		padding-left: 0;
	}
}

.form-control-sm {
	padding: 0.25rem 0.5rem;
	font-size: 0.875rem;
	line-height: 1.14285714;
	border-radius: 3px;
}

.input-group-sm> {
	.form-control,
	.input-group-text>.input-group-text,
	.input-group-text>.input-group-text,
	.input-group-text>.btn,
	.input-group-text>.btn {
		padding: 0.25rem 0.5rem;
		font-size: 0.875rem;
		line-height: 1.14285714;
		border-radius: 3px;
	}
}

.form-control-lg {
	padding: 0.5rem 1rem;
	font-size: 1.125rem;
	line-height: 1.44444444;
	border-radius: 3px;
}

.input-group-lg> {
	.form-control,
	.input-group-text>.input-group-text,
	.input-group-text>.input-group-text,
	.input-group-text>.btn,
	.input-group-text>.btn {
		padding: 0.5rem 1rem;
		font-size: 1.125rem;
		line-height: 1.44444444;
		border-radius: 3px;
	}
}

.form-group {
	margin-bottom: 1rem;
}

.form-text {
	display: block;
	margin-top: 0.25rem;
}

.form-row {
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -5px;
	margin-left: -5px;
	> {
		.col,
		[class*="col-"] {
			padding-right: 5px;
			padding-left: 5px;
		}
	}
}

.form-check {
	position: relative;
	display: block;
	padding-left: 1.25rem;
}

.form-check-input {
	position: absolute;
	margin-top: 0.3rem;
	margin-left: -1.25rem;
	&:disabled~.form-check-label {
		color: #505662;
	}
}

.form-check-label {
	margin-bottom: 0;
}

.form-check-inline {
	display: -ms-inline-flexbox;
	display: -webkit-inline-box;
	display: inline-flex;
	-ms-flex-align: center;
	-webkit-box-align: center;
	align-items: center;
	padding-left: 0;
	margin-right: 0.75rem;
	.form-check-input {
		position: static;
		margin-top: 0;
		margin-right: 0.3125rem;
		margin-left: 0;
	}
}

.form-inline {
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-flow: row wrap;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	flex-flow: row wrap;
	-ms-flex-align: center;
	-webkit-box-align: center;
	align-items: center;
	.form-check {
		width: 100%;
	}
}

@media (min-width: 576px) {
	.form-inline {
		label {
			display: -ms-flexbox;
			display: -webkit-box;
			display: flex;
			-ms-flex-align: center;
			-webkit-box-align: center;
			align-items: center;
			-ms-flex-pack: center;
			-webkit-box-pack: center;
			justify-content: center;
			margin-bottom: 0;
		}
		.form-group {
			display: -ms-flexbox;
			display: -webkit-box;
			display: flex;
			-ms-flex: 0 0 auto;
			-webkit-box-flex: 0;
			flex: 0 0 auto;
			-ms-flex-flow: row wrap;
			-webkit-box-orient: horizontal;
			-webkit-box-direction: normal;
			flex-flow: row wrap;
			-ms-flex-align: center;
			-webkit-box-align: center;
			align-items: center;
			margin-bottom: 0;
		}
		.form-control {
			display: inline-block;
			width: auto;
			vertical-align: middle;
		}
		.form-control-plaintext {
			display: inline-block;
		}
		.input-group,
		.form-select {
			width: auto;
		}
		.form-check {
			display: -ms-flexbox;
			display: -webkit-box;
			display: flex;
			-ms-flex-align: center;
			-webkit-box-align: center;
			align-items: center;
			-ms-flex-pack: center;
			-webkit-box-pack: center;
			justify-content: center;
			width: auto;
			padding-left: 0;
		}
		.form-check-input {
			position: relative;
			margin-top: 0;
			margin-right: 0.25rem;
			margin-left: 0;
		}
		.custom-control {
			-ms-flex-align: center;
			-webkit-box-align: center;
			align-items: center;
			-ms-flex-pack: center;
			-webkit-box-pack: center;
			justify-content: center;
		}
		.custom-control-label {
			margin-bottom: 0;
		}
	}
}


/*------- Forms -------*/

.form-group {
	display: block;
}

.form-label {
	display: block;
	margin-bottom: .375rem;
	font-weight: 500;
	font-size: 0.875rem;
	.form-fieldset {
		background: #f8f9fa;
		border: 1px solid $border;
		padding: 1rem;
		border-radius: 3px;
		margin-bottom: 1rem;
	}
	.form-required {
		color: $danger;
		&:before {
			content: ' ';
		}
	}
	.form-help {
		display: inline-block;
		width: 1rem;
		height: 1rem;
		text-align: center;
		line-height: 1rem;
		color: #a7b4c9;
		background: #f8f9fa;
		border-radius: 50%;
		font-size: 0.75rem;
		-webkit-transition: .3s background-color, .3s color;
		transition: .3s background-color, .3s color;
		text-decoration: none;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		&:hover,
		&[aria-describedby] {
			background: $blue;
			color: $white;
		}
	}
}

.form-help {
	&:hover,
	&[aria-describedby] {
		background: $blue;
		color: $white;
	}
}

.form-group {
	display: block;
}

.form-label {
	display: block;
	margin-bottom: .375rem;
	font-weight: 500;
	font-size: 0.875rem;
}

.form-label-small {
	float: right;
	font-weight: 400;
	font-size: 87.5%;
}

.form-footer {
	margin-top: 2rem;
}

.form-control.header-search {
	background: $white-1;
	border: 1px solid $white-2 !important;
	color: $white;
	width: 100%;
	border-radius: 12em;
	&::-webkit-input-placeholder,
	&:-ms-input-placeholder,
	&::-ms-input-placeholder,
	&::placeholder {
		color: $white;
	}
	&:hover,
	&:focus {
		border-bottom: 1px solid $white-2 !important;
		border: 0;
		-webkit-box-shadow: none;
		box-shadow: none;
	}
}


/*------- Forms -------*/

.input-lg {
	height: 46px !important;
	padding: 10px 16px;
	font-size: 18px;
	line-height: 1.3333333;
	border-radius: 7px;
}

.input-sm {
	height: 30px !important;
	padding: 5px 10px;
	font-size: 12px;
	line-height: 1.5;
	border-radius: 7px;
}

.input-icon {
	position: relative;
	.form-control {
		&:not(:last-child),
		&:not(:first-child) {
			padding-left: 2.5rem;
		}
	}
}

.input-icon-addon {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	color: $white;
	display: -ms-flexbox;
	display: -webkit-box;
	display: flex;
	-ms-flex-align: center;
	-webkit-box-align: center;
	align-items: center;
	-ms-flex-pack: center;
	-webkit-box-pack: center;
	justify-content: center;
	min-width: 2.5rem;
	pointer-events: none;
	&:last-child {
		left: auto;
		right: 0;
	}
}

.fileinput-button {
	background: none repeat scroll 0 0 #f9faff;
	border: 1px solid #e6e6e6;
	float: left;
	margin-right: 4px;
	overflow: hidden;
	position: relative;
	input {
		cursor: pointer;
		direction: ltr;
		font-size: 23px;
		margin: 0;
		opacity: 0;
		position: absolute;
		right: 0;
		top: 0;
		-webkit-transform: translate(-300px, 0px) scale(4);
		transform: translate(-300px, 0px) scale(4);
	}
}

.fileupload-buttonbar {
	.btn,
	.toggle {
		margin-bottom: 5px;
	}
}

.files .progress {
	width: 200px;
}

.fileupload-processing .fileupload-loading {
	display: block;
}

* {
	html .fileinput-button {
		line-height: 24px;
		margin: 1px -3px 0 0;
	}
	+html .fileinput-button {
		margin: 1px 0 0;
		padding: 2px 15px;
	}
}

@media (max-width: 767px) {
	.files {
		.btn span {
			display: none;
		}
		.preview * {
			width: 40px;
		}
		.name * {
			display: inline-block;
			width: 80px;
			word-wrap: break-word;
		}
		.progress {
			width: 20px;
		}
		.delete {
			width: 60px;
		}
	}
}

.was-validated .form-control:valid,
.form-control.is-valid,
.was-validated .form-select:valid,
.form-select.is-valid {
	border-color: $success;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus,
.was-validated .form-select:valid:focus,
.form-select.is-valid:focus {
	border-color: $success;
	-webkit-box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.25);
	box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.25);
}

.was-validated .form-control:valid~ {
	.valid-feedback,
	.valid-tooltip {
		display: block;
	}
}

.form-control.is-valid~ {
	.valid-feedback,
	.valid-tooltip {
		display: block;
	}
}

.was-validated .form-select:valid~ {
	.valid-feedback,
	.valid-tooltip {
		display: block;
	}
}

.form-select.is-valid~ {
	.valid-feedback,
	.valid-tooltip {
		display: block;
	}
}

.was-validated .form-check-input:valid~.form-check-label,
.form-check-input.is-valid~.form-check-label {
	color: $success;
}

.was-validated .form-check-input:valid~ {
	.valid-feedback,
	.valid-tooltip {
		display: block;
	}
}

.form-check-input.is-valid~ {
	.valid-feedback,
	.valid-tooltip {
		display: block;
	}
}

.was-validated .custom-control-input:valid~.custom-control-label,
.custom-control-input.is-valid~.custom-control-label {
	color: $success;
}

.was-validated .custom-control-input:valid~.custom-control-label::before,
.custom-control-input.is-valid~.custom-control-label::before {
	background-color: #9eff3b;
}

.was-validated .custom-control-input:valid~ {
	.valid-feedback,
	.valid-tooltip {
		display: block;
	}
}

.custom-control-input.is-valid~ {
	.valid-feedback,
	.valid-tooltip {
		display: block;
	}
}

.was-validated .custom-control-input:valid:checked~.custom-control-label::before,
.custom-control-input.is-valid:checked~.custom-control-label::before {
	background-color: #78ed00;
}

.was-validated .custom-control-input:valid:focus~.custom-control-label::before,
.custom-control-input.is-valid:focus~.custom-control-label::before {
	-webkit-box-shadow: 0 0 0 1px #f9faff, 0 0 0 2px rgba(94, 186, 0, 0.25);
	box-shadow: 0 0 0 1px #f9faff, 0 0 0 2px rgba(94, 186, 0, 0.25);
}

.was-validated .form-file-input:valid~.form-file-label,
.form-file-input.is-valid~.form-file-label {
	border-color: $success;
}

.was-validated .form-file-input:valid~.form-file-label::before,
.form-file-input.is-valid~.form-file-label::before {
	border-color: inherit;
}

.was-validated .form-file-input:valid~ {
	.valid-feedback,
	.valid-tooltip {
		display: block;
	}
}

.form-file-input.is-valid~ {
	.valid-feedback,
	.valid-tooltip {
		display: block;
	}
}

.was-validated .form-file-input:valid:focus~.form-file-label,
.form-file-input.is-valid:focus~.form-file-label {
	-webkit-box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.25);
	box-shadow: 0 0 0 2px rgba(94, 186, 0, 0.25);
}

.invalid-feedback {
	display: none;
	width: 100%;
	margin-top: 0.25rem;
	font-size: 87.5%;
	color: $danger;
}

.invalid-tooltip {
	position: absolute;
	top: 100%;
	z-index: 5;
	display: none;
	max-width: 100%;
	padding: .5rem;
	margin-top: .1rem;
	font-size: .875rem;
	line-height: 1;
	color: $white;
	background-color: rgba(205, 32, 31, 0.8);
	border-radius: .2rem;
}

.was-validated .form-control:invalid,
.form-control.is-invalid,
.was-validated .form-select:invalid,
.form-select.is-invalid {
	border-color: $danger;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus,
.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
	border-color: $danger;
	-webkit-box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.25);
	box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.25);
}

.was-validated .form-control:invalid~ {
	.invalid-feedback,
	.invalid-tooltip {
		display: block;
	}
}

.form-control.is-invalid~ {
	.invalid-feedback,
	.invalid-tooltip {
		display: block;
	}
}

.was-validated .form-select:invalid~ {
	.invalid-feedback,
	.invalid-tooltip {
		display: block;
	}
}

.form-select.is-invalid~ {
	.invalid-feedback,
	.invalid-tooltip {
		display: block;
	}
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
	color: $danger;
}

.was-validated .form-check-input:invalid~ {
	.invalid-feedback,
	.invalid-tooltip {
		display: block;
	}
}

.form-check-input.is-invalid~ {
	.invalid-feedback,
	.invalid-tooltip {
		display: block;
	}
}

.was-validated .custom-control-input:invalid~.custom-control-label,
.custom-control-input.is-invalid~.custom-control-label {
	color: $danger;
}

.was-validated .custom-control-input:invalid~.custom-control-label::before,
.custom-control-input.is-invalid~.custom-control-label::before {
	background-color: #ec8080;
}

.was-validated .custom-control-input:invalid~ {
	.invalid-feedback,
	.invalid-tooltip {
		display: block;
	}
}

.custom-control-input.is-invalid~ {
	.invalid-feedback,
	.invalid-tooltip {
		display: block;
	}
}

.was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
.custom-control-input.is-invalid:checked~.custom-control-label::before {
	background-color: #e23e3d;
}

.was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
.custom-control-input.is-invalid:focus~.custom-control-label::before {
	-webkit-box-shadow: 0 0 0 1px #f9faff, 0 0 0 2px rgba(205, 32, 31, 0.25);
	box-shadow: 0 0 0 1px #f9faff, 0 0 0 2px rgba(205, 32, 31, 0.25);
}

.was-validated .form-file-input:invalid~.form-file-label,
.form-file-input.is-invalid~.form-file-label {
	border-color: $danger;
}

.was-validated .form-file-input:invalid~.form-file-label::before,
.form-file-input.is-invalid~.form-file-label::before {
	border-color: inherit;
}

.was-validated .form-file-input:invalid~ {
	.invalid-feedback,
	.invalid-tooltip {
		display: block;
	}
}

.form-file-input.is-invalid~ {
	.invalid-feedback,
	.invalid-tooltip {
		display: block;
	}
}

.was-validated .form-file-input:invalid:focus~.form-file-label,
.form-file-input.is-invalid:focus~.form-file-label {
	-webkit-box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.25);
	box-shadow: 0 0 0 2px rgba(205, 32, 31, 0.25);
}

select {
	&.form-control {
		&.select-lg {
			height: 46px !important;
			padding: 10px 16px !important;
			font-size: 17px !important;
			line-height: 1.3333333 !important;
			border-radius: 3px !important;
		}
		&.select-sm {
			height: 30px !important;
			padding: 5px 10px !important;
			line-height: 1 !important;
			border-radius: 3px !important;
		}
		&:not([size]):not([multiple]) {
			height: 2.425rem;
		}
		&:focus::-ms-value {
			color: #3d4e67;
			background-color: $white;
		}
	}
	&.form-control-sm:not([size]):not([multiple]) {
		height: calc(1.8125rem + 2px);
	}
}

.input-group-sm> {
	select.form-control:not([size]):not([multiple]),
	.input-group-text>select.input-group-text:not([size]):not([multiple]),
	.input-group-text>select.input-group-text:not([size]):not([multiple]),
	.input-group-text>select.btn:not([size]):not([multiple]),
	.input-group-text>select.btn:not([size]):not([multiple]) {
		height: calc(1.8125rem + 2px);
	}
}

select.form-control-lg:not([size]):not([multiple]) {
	height: calc(2.6875rem + 2px);
}

.input-group-lg> {
	select.form-control:not([size]):not([multiple]),
	.input-group-text>select.input-group-text:not([size]):not([multiple]),
	.input-group-text>select.input-group-text:not([size]):not([multiple]),
	.input-group-text>select.btn:not([size]):not([multiple]),
	.input-group-text>select.btn:not([size]):not([multiple]) {
		height: calc(2.6875rem + 2px);
	}
}


/*--inputs incremet&decrement--*/

.input-indec {
	.quantity-left-minus.btn {
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
		border-right: 0;
	}
	.quantity-right-plus.btn {
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;
		border-left: 0;
	}
}

.state-valid {
	padding-right: 2rem;
	background: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%235eba00' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'><polyline points='20 6 9 17 4 12'></polyline></svg>") no-repeat center right 0.5rem / 1rem;
}

.state-invalid {
	padding-right: 2rem;
	background: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23cd201f' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'><line x1='18' y1='6' x2='6' y2='18'></line><line x1='6' y1='6' x2='18' y2='18'></line></svg>") no-repeat center right 0.5rem / 1rem;
}
