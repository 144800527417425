/*-----Alerts-----*/

.alert {
	position: relative;
	padding: 0.75rem 1.25rem;
	margin-bottom: 1rem;
	border: 1px solid transparent;
	border-radius: 3px;
	&:first-child {
		margin-bottom: 1rem !important;
	}
	&:last-child {
		margin-bottom: 0;
	}
}

.alert-heading {
	color: inherit;
}

.alert-link {
	font-weight: 500;
	&:hover,
	a:hover {
		text-decoration: underline;
	}
}

.alert-dismissible {
	padding-right: 3.90625rem;
}

hr.message-inner-separator {
	clear: both;
	margin-top: 10px;
	margin-bottom: 13px;
	border: 0;
	height: 1px;
	background-image: -webkit-linear-gradient(left, $border, $border, $border);
	background-image: -moz-linear-gradient(left, $border, $border, $border);
	background-image: -ms-linear-gradient(left, $border, $border, $border);
	background-image: -o-linear-gradient(left, $border, $border, $border);
}

.alert-dismissible .btn-close {
	position: absolute;
	top: 0;
	right: 0;
	padding: 0.75rem 1.25rem;
	color: inherit;
}

.alert-secondary {
	color: $white;
	background-color: $secondary;
	border-color: $secondary;
}

.alert-success {
	color: #fcfdff;
	background-color: $success;
	border-color: $success;
	hr {
		border-top-color: #c5e7a4;
	}
	.alert-link {
		color: #172e00;
	}
}

.alert-info {
	color: #fcfdff;
	background-color: $info;
	border-color: $info;
	hr {
		border-top-color: #b3dcf9;
	}
	.alert-link {
		color: #193c56;
	}
}

.alert-warning {
	color: #fcfdff;
	background-color: $yellow;
	border-color: $yellow;
	hr {
		border-top-color: #fae8a4;
	}
	.alert-link {
		color: #4d3f05;
	}
}

.alert-danger {
	color: #fcfdff;
	background-color: $danger;
	border-color: $danger;
	hr {
		border-top-color: #ecacab;
	}
	.alert-link {
		color: #3f0a09;
	}
}

.alert-light {
	color: #818182;
	background-color: #fefefe;
	border-color: #fdfdfe;
	hr {
		border-top-color: #ececf6;
	}
	.alert-link {
		color: #686868;
	}
}

.alert-dark {
	color: #1b1e21;
	background-color: #d6d8d9;
	border-color: #c6c8ca;
	hr {
		border-top-color: #b9bbbe;
	}
	.alert-link {
		color: #040505;
	}
}

@-webkit-keyframes progress-bar-stripes {
	from {
		background-position: 1rem 0;
	}
	to {
		background-position: 0 0;
	}
}

@keyframes progress-bar-stripes {
	from {
		background-position: 1rem 0;
	}
	to {
		background-position: 0 0;
	}
}


/*-----Alerts-----*/


/*-----Alerts------*/

.alert {
	font-size: 0.9375rem;
}

.alert-icon {
	padding-left: 3rem;
	>i {
		color: inherit !important;
		font-size: 1rem;
		position: absolute;
		top: 1rem;
		left: 1rem;
	}
}

.alert-avatar {
	padding-left: 3.75rem;
	.avatar {
		position: absolute;
		top: .5rem;
		left: .75rem;
	}
}
.alert-outline-primary {
	background-color: transparent;
	color: $primary;
	border-color: $primary;
  
	&:hover {
	  color:  $white;
	  background-color: $primary;
	  border-color: $primary;
  
	  .close {
		color:  $white;
	  }
	}
  }
  
  .alert-outline-secondary {
	background-color: transparent;
	color: $secondary;
	border-color: $secondary;
  
	&:hover {
	  color:  $white;
	  background-color: $secondary;
	  border-color: $secondary;
  
	  .close {
		color:  $white;
	  }
	}
  }
  
  .alert-outline-success {
	background-color: transparent;
	color: $success;
	border-color: $success;
  
	&:hover {
	  color:  $white;
	  background-color: $success;
	  border-color: $success;
  
	  .close {
		color:  $white;
	  }
	}
  }
  
  .alert-outline-warning {
	background-color: transparent;
	color: $warning;
	border-color: $warning;
  
	&:hover {
	  color:  $white;
	  background-color: $warning;
	  border-color: $warning;
  
	  .close {
		color:  $white;
	  }
	}
  }
  
  .alert-outline-danger {
	background-color: transparent;
	color: $danger;
	border-color: $danger;
  
	&:hover {
	  color:  $white;
	  background-color: $danger;
	  border-color: $danger;
  
	  .close {
		color:  $white;
	  }
	}
  }
  
  .alert-outline-info {
	background-color: transparent;
	color: $info;
	border-color:  $info;
  
	&:hover {
	  color:  $white;
	  background-color:  $info;
	  border-color:  $info;
  
	  .close {
		color:  $white;
	  }
	}
  }