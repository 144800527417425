.admin-navbar {
	background: $white;
	box-shadow: 0px 22px 68px -9px $black-15;
	.container {
		position: relative;
	}
	.nav {
		padding: 0;
		margin: 0;
		.nav-link {
			position: relative;
		}
	}
	.nav-item {
		text-align: center;
		position: static;
		&.active {
			.nav-link {
				background-color: $white;
				background-repeat: repeat-x;
				border-width: 0;
			}
			+.nav-item .nav-link {
				border-left-width: 0;
			}
		}
		&:last-child .nav-link {
			border-right-width: 1px;
		}
		&.with-sub {
			>a::after {
				content: '\f107';
				font-family: 'FontAwesome';
				font-weight: 400;
				margin-left: 4px;
				margin-top: 0;
				opacity: .7;
			}
			&:hover>.sub-item,
			&:focus>.sub-item {
				display: block;
			}
		}
	}
	.nav-link {
		border: 0;
		color: inherit;
		color: #a7b4c9;
		transition: .3s border-color;
		font-weight: 500;
		padding: 1rem 1.42rem;
		font-size: 13px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		border-right-width: 0;
		text-transform: uppercase;
		.icon {
			font-size: 21px;
			line-height: 0;
		}
		i {
			margin-right: 8px;
		}
		.icon.ion-ios-analytics-outline {
			font-size: 20px;
			margin-top: -2px;
		}
		&.active {
			font-weight: 600;
			border-bottom: 1px solid;
		}
		.square-8 {
			margin-left: 3px;
			background-color: $white;
			border-radius: 100%;
		}
	}
	.mega-dropdown {
		position: static;
		.sub-item {
			padding: 25px;
			width: 100%;
			a {
				padding: 5px 9px;
				margin: 0;
			}
			.section-label {
				border-bottom: 1px solid rgba(137, 42, 226, 0.1);
				padding-bottom: 5px;
				margin-bottom: 15px;
				font-size: 14px;
				font-weight: 500;
				display: block;
				text-align: left;
				letter-spacing: 1px;
			}
		}
	}
	.sub-item {
		background-color: $white;
		position: absolute;
		top: 51px;
		left: 0;
		border-top: 0;
		padding: 10px 0;
		box-shadow: 0px 22px 68px -9px $black-15;
		width: 100%;
		font-size: 14px;
		font-weight: 400;
		z-index: 9999;
		display: none;
		color: #111;
		ul {
			list-style: none;
			padding: 0;
			margin: 0;
			text-align: left;
			a {
				display: block;
				padding: 0.5rem 1.5rem;
				color: #a7b4c9;
				&:active {
					color: $white;
					text-decoration: none;
				}
			}
		}
		.sub-with-sub {
			position: relative;
			&::after {
				content: '\f054';
				font-family: "FontAwesome";
				font-size: 10px;
				position: absolute;
				top: 9px;
				right: 20px;
				color: #a7b4c9;
			}
			ul {
				display: none;
				padding-left: 15px;
				a {
					display: block;
					padding: 6px 10px;
				}
			}
			&:hover ul,
			&:focus ul {
				display: block;
			}
		}
	}
}

@media (min-width: 992px) {
	.admin-navbar .nav-item {
		position: relative;
	}
}

@media (min-width: 992px) {
	.admin-navbar .nav-item.with-sub>a::after {
		margin-left: 8px;
	}
}

@media (min-width: 992px) {
	.admin-navbar .nav-link .square-8 {
		margin-left: 5px;
	}
}

@media (min-width: 992px) {
	.admin-navbar .nav-link span :not(.square-8) {
		display: block;
	}
}

@media (max-width: 991px) {
	.admin-navbar {
		.nav-link {
			span :not(.square-8) {
				display: block;
			}
			width: 100%;
			text-align: left;
			display: block;
		}
		.nav-item {
			min-width: 100%;
		}
		&.collapse.show {
			display: block;
		}
		display: none;
		.mega-dropdown .sub-item .section-label {
			margin-top: 10px;
		}
		.sub-item .sub-with-sub ul li a {
			padding-left: 20px;
		}
		.nav {
			.nav-item.with-sub .nav-link.active {
				position: relative !important;
			}
			.nav-link .sub-item {
				position: inherit !important;
			}
		}
		.container {
			background: $white;
			z-index: 100;
		}
		.sub-item {
			position: relative !important;
			top: 0 !important;
		}
	}
	.with-sub .nav-link,
	.nav-item.with-sub {
		display: block !important;
	}
	.about-con {
		border-bottom: 1px solid $border;
	}
}

@media (min-width: 992px) {
	.admin-navbar .sub-item {
		width: 200px;
	}
}

@media (max-width: 1279px) and (min-width: 992px) {
	.admin-navbar .nav-link {
		padding: 1rem 0.57rem;
	}
	.pabout.p-8 {
		padding: 17px !important;
	}
}

@media (min-width: 992px) {
	.admin-navbar .sub-item .sub-with-sub ul {
		padding-left: 0;
		position: absolute;
		top: 0;
		right: -200px;
		width: 200px;
		background-color: $white;
		padding: 10px 0;
		box-shadow: 0px 22px 68px -9px $black-15;
	}
}