@charset "UTF-8";
/*-------- Bootstrap Framework -------*/


* {
	box-sizing: border-box;

	&::before,
	&::after {
		box-sizing: border-box;
	}
}

html {
	line-height: 1.15;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	-ms-overflow-style: scrollbar;
	-webkit-tap-highlight-color: transparent;
}

@-ms-viewport {
	width: device-width;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
	display: block;
}

.secondlevel ul {
	background: none !important;
	margin-left: 5px !important;
	margin-right: 5px !important;
}

html {
	font-size: 16px;
	height: 100%;
}

body {
	margin: 0;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.5;
	color: $color;
	text-align: left;
	background-color: $background;
	font-family: 'Maven Pro', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	-webkit-tap-highlight-color: transparent;
	-webkit-text-size-adjust: none;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
	-webkit-font-feature-settings: "liga" 0;
	font-feature-settings: "liga" 0;
	height: 100%;
	overflow-y: scroll;
	position: relative;
}


[tabindex="-1"]:focus {
	outline: 0 !important;
}

hr {
	box-sizing: content-box;
	height: 0;
	overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin-top: 0;
	margin-bottom: 0.66em;
}

p {
	margin-top: 0;
	margin-bottom: 1rem;
}

abbr {

	&[title],
	&[data-original-title] {
		text-decoration: underline;
		-webkit-text-decoration: underline dotted;
		text-decoration: underline dotted;
		cursor: help;
		border-bottom: 0;
	}
}

address {
	margin-bottom: 1rem;
	font-style: normal;
	line-height: inherit;
}

ol,
ul,
dl {
	margin-top: 0;
	margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
	margin-bottom: 0;
}

dt {
	font-weight: 700;
}

dd {
	margin-bottom: .5rem;
	margin-left: 0;
}

blockquote {
	margin: 0 0 1rem;
}

dfn {
	font-style: italic;
}

b,
strong {
	font-weight: bolder;
}

small {
	font-size: 80%;
}

sub,
sup {
	position: relative;
	font-size: 75%;
	line-height: 0;
	vertical-align: baseline;
}

sub {
	bottom: -.25em;
}

sup {
	top: -.5em;
}

a {
	color: $color;
	text-decoration: none;
	background-color: transparent;
	-webkit-text-decoration-skip: objects;

	&:hover {
		color: $primary  !important;
		text-decoration: underline;
	}

	&:not([href]):not([tabindex]) {
		color: inherit;
		text-decoration: none;

		&:hover {
			color: inherit;
			text-decoration: none;
		}

		&:focus {
			color: inherit;
			text-decoration: none;
			outline: 0;
		}
	}
}

pre,
code,
kbd,
samp {
	font-family: monospace, monospace;
	font-size: 1em;
}

pre {
	margin-top: 0;
	margin-bottom: 1rem;
	overflow: auto;
	-ms-overflow-style: scrollbar;
}

figure {
	margin: 0 0 1rem;
}

img {
	vertical-align: middle;
	border-style: none;
}

svg:not(:root) {
	overflow: hidden;
}

caption {
	padding-top: 0.75rem;
	padding-bottom: 0.75rem;
	color: #505662;
	text-align: left;
	caption-side: bottom;
}

th {
	text-align: inherit;
}

label {
	display: inline-block;
	margin-bottom: 0.5rem;
}

button {
	border-radius: 0;

	&:focus {
		outline: 1px dotted;
		outline: 5px auto -webkit-focus-ring-color;
	}
}

input,
button,
select,
optgroup,
textarea {
	margin: 0;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}

button,
input {
	overflow: visible;
}

button,
select {
	text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
	-webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
	padding: 0;
	border-style: none;
}

input {

	&[type="radio"],
	&[type="checkbox"] {
		box-sizing: border-box;
		padding: 0;
	}

	&[type="date"],
	&[type="time"],
	&[type="datetime-local"],
	&[type="month"] {
		-webkit-appearance: listbox;
	}
}

textarea {
	overflow: auto;
	resize: vertical;
}

fieldset {
	min-width: 0;
	padding: 0;
	margin: 0;
	border: 0;
}

legend {
	display: block;
	width: 100%;
	max-width: 100%;
	padding: 0;
	margin-bottom: .5rem;
	font-size: 1.5rem;
	line-height: inherit;
	color: inherit;
	white-space: normal;
}

progress {
	vertical-align: baseline;
}

[type="number"] {

	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		height: auto;
	}
}

[type="search"] {
	outline-offset: -2px;
	-webkit-appearance: none;

	&::-webkit-search-cancel-button,
	&::-webkit-search-decoration {
		-webkit-appearance: none;
	}
}

::-webkit-file-upload-button {
	font: inherit;
	-webkit-appearance: button;
}

output {
	display: inline-block;
}

summary {
	display: list-item;
	cursor: pointer;
}

template {
	display: none;
}

[hidden] {
	display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
	margin-bottom: 0.66em;
	font-family: 'Quicksand', sans-serif;
	font-weight: 500;
	line-height: 1.1;
}

h1,
.h1 {
	font-size: 2.5rem;
	font-weight: 400;
}

h2,
.h2 {
	font-size: 1.75rem;
}

h3,
.h3 {
	font-size: 1.5rem;
}

h4,
.h4 {
	font-size: 1.125rem;
}

h5,
.h5 {
	font-size: 1rem;
}

h6,
.h6 {
	font-size: 0.875rem;
}

.lead {
	font-size: 1.171875rem;
	font-weight: 300;
}

hr {
	margin-top: 1rem;
	margin-bottom: 1rem;
	border: 0;
	border-top: 1px solid $border;
	background-color: transparent;
	opacity: 1;
}

small,
.small {
	font-size: 87.5%;
	font-weight: 400;
}

mark,
.mark {
	padding: 0.2em;
	background-color: #fcf8e3;
}

.initialism {
	font-size: 90%;
	text-transform: uppercase;
}

.blockquote {
	margin-bottom: 1rem;
	font-size: 1.171875rem;
}

.blockquote-footer {
	display: block;
	font-size: 80%;
	color: #868e96;

	&::before {
		content: "\2014 \00A0";
	}
}

.img-fluid {
	max-width: 100%;
	height: auto;
}

.cover-image {
	background-size: cover !important;
	position: relative;
}

.figure {
	display: inline-block;
}

.figure-img {
	margin-bottom: 0.5rem;
	line-height: 1;
}

.figure-caption {
	font-size: 90%;
	color: #868e96;
}

.fs-10 {
	font-size: 10px !important;
}

.fs-12 {
	font-size: 12px !important;
}

.fs-13 {
	font-size: 13px !important;
}

.fs-14 {
	font-size: 14px !important;
}

.fs-16 {
	font-size: 16px !important;
}

.fs-18 {
	font-size: 18px !important;
}

.fs-20 {
	font-size: 20px !important;
}

.fs-30 {
	font-size: 30px;
}

.fs-40 {
	font-size: 40px;
}

.fs-50 {
	font-size: 50px;
}

code,
kbd,
pre,
samp {
	font-family: Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

code {
	font-size: 85%;
	color: inherit;
	word-break: break-word;
}

a>code {
	color: inherit;
}

kbd {
	padding: 0.2rem 0.4rem;
	font-size: 85%;
	color: $white;
	background-color: $dark;
	border-radius: 3px;

	kbd {
		padding: 0;
		font-size: 100%;
		font-weight: 700;
	}
}

pre {
	display: block;
	font-size: 85%;
	color: #a7b4c9;

	code {
		font-size: inherit;
		color: inherit;
		word-break: normal;
	}
}

.pre-scrollable {
	max-height: 340px;
	overflow-y: scroll;
}

.container,
.container-fluid {
	width: 100%;
	padding-right: 0.75rem;
	padding-left: 0.75rem;
	margin-right: auto;
	margin-left: auto;
}

@media (min-width: 576px) {
	.container {
		max-width: 540px;
	}
}

@media (min-width: 768px) {
	.container {
		max-width: 720px;
	}
}

@media (min-width: 992px) {
	.container {
		max-width: 960px;
	}
}

@media (min-width: 1280px) {
	.container {
		max-width: 1200px;
	}
}

.row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -0.75rem;
	margin-left: -0.75rem;
}

.g-0 {
	margin-right: 0;
	margin-left: 0;

	> {

		.col,
		[class*="col-"] {
			padding-right: 0;
			padding-left: 0;
		}
	}
}


/*-------- Bootstrap Framework -------*/