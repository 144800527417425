/*------ Breadcrumb ------*/

.breadcrumb {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 0 !important;
	list-style: none;
	border-radius: 3px;
	left: 0;
	bottom: 0px;
	padding: 0 !important;
	background-color: transparent !important;
}

.breadcrumb-item a {
	color: $white-8;
}

.page-header .breadcrumb-item+.breadcrumb-item::before {
	color: $color;
}

.breadcrumb-item {
	+.breadcrumb-item {
		&::before {
			display: inline-block;
			padding-right: 0.5rem;
			padding-left: 0.5rem;
			color: $white-3;
			content: "/";
		}
		&:hover::before {
			text-decoration: underline;
			text-decoration: none;
		}
	}
	&.active {
		color: $color;
	}
}

.breadcrumb1 {
	display: flex;
	flex-wrap: wrap;
	padding: .75rem 1rem;
	margin-bottom: 0;
	list-style: none;
	background-color: #f9faff;
	border-radius: .25rem;
}

.breadcrumb-item1 {
	a {
		color: #7b838f;
		&:hover {
			text-decoration: underline;
		}
	}
	+.breadcrumb-item1 {
		&::before {
			display: inline-block;
			padding-right: 0.5rem;
			padding-left: 0.5rem;
			content: "/";
		}
		&:hover::before {
			text-decoration: underline;
			text-decoration: none;
		}
	}
	&.active {
		color: #a7b4c9;
	}
}

.breadcrumb-1,
.breadcrumb-2 {
	ol {
		position: relative;
		display: block;
		width: 100%;
		text-align: right;
	}
	li {
		text-align: right;
		display: contents;
	}
}

.breadcrumb-3,
.breadcrumb-4 {
	ol {
		position: relative;
		display: block;
		width: 100%;
		text-align: center;
	}
	li {
		display: contents;
		text-align: center;
	}
}
.breadcrumb2 {
	display: flex;
	flex-wrap: wrap;
    padding: .75rem 1rem;
	margin-bottom: 1rem;
	list-style: none;
	background-color: #f9faff;
	border-radius: .25rem;
  }
  
  .breadcrumb-item2 {
	a {
	  color: #5c5776;
  
	  &:hover {
		text-decoration: none;
	  }
	}
  
	+ .breadcrumb-item2 {
	  &::before {
		display: inline-block;
		padding-right: .5rem;
		padding-left: .5rem;
		content: "\e933";
		font-family: "feather";
		opacity: 0.6;
		color: #a8a3b0;
		margin-top: 1px;
	  }
  
	  &:hover::before {
		text-decoration: none;
	  }
	}
  
	&.active {
	  color: $primary;
	}
  }
  @media (max-width: 480px) {
	  .breadcrumb-item+.breadcrumb-item {
		  &::before {
			  display: inline-block;
			  padding-right: 0.2rem;
			  padding-left: 0.2rem;
			  color: $color;
			  content: "/";
		  }
		  padding-left: 0;
	  }
	  .page-header {
		  display: block !important;
		  padding: 10px 0 !important;
		  .page-title {
			  margin-bottom: 5px;
		  }
	  }
  }


/*------ Breadcrumb ------*/