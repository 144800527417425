.about-img {
	background: url('../images/other/about.jpg');
	background-size: cover;
	background-position: center;
	border-radius: 2px;
	margin-top: 1.5rem;
}

.login-img {
	background: url('../images/other/login.jpg');
	background-size: cover;
	width: 100%;
	height: 100%;
}

.error-img {
	background: url('../images/other/about.jpg');
	background-size: cover;
	width: 100%;
	height: 100%;
	.card {
		background: $white-2;
		box-shadow: 0 0.0625em 0.1875em 0 rgb(7, 98, 113), 0 0.5em 0 -0.25em $white-3, 0 0.5em 0.1875em -0.25em rgb(8, 99, 114), 0 1em 0 -0.5em rgba(255, 255, 255, 0.35), 0 1em 0.1875em -0.5em rgb(9, 102, 117);
	}
	&:before {
		content: '';
		display: block;
		position: absolute;
		background: $primary-gradient;
		width: 100%;
		height: 100%;
		right: 0;
		top: 0;
	}
}

.constuction-logo {
	border-bottom: 3px solid;
	border-radius: 3px;
}

.coupon-code .coupon {
	background: rgba(242, 187, 26, 0.1);
	border: 2px dashed #f2bb1a;
	text-align: center;
	border-radius: 2px;
}

.construction-image {
	&:before {
		position: absolute;
		content: '';
		width: 100%;
		height: 100%;
		display: block;
		z-index: 1;
		left: 0;
		right: 0;
	}
	.card {
		background: rgb(255, 255, 255);
	}
}