.panel-body-landing:hover>.table>tbody>tr>td {
	color: $white !important;
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
}

.panel-heading-landing {
	background: #f7f7f7 !important;
	padding: 20px !important;
	border-top-left-radius: 10px !important;
	border-top-right-radius: 10px !important;
	border: solid 2px $secondary !important;
	border-bottom: none !important;
	text-align: center;
	margin-top: 20px;
}

.panel-heading-landing-box {
	background: $secondary !important;
	color: $white !important;
	font-size: 16px !important;
	text-align: center;
	border-top: none !important;
}

.panel-title-landing {
	color: $secondary !important;
	font-size: 35px;
	font-weight: bold;
}

.panel-body-landing {
	border: solid 2px $secondary !important;
	border-top: none !important;
	border-bottom: none !important;
	text-align: center;
	background: $white;
}

.panel-footer-landing {
	border: solid 2px $secondary !important;
	border-bottom-left-radius: 10px !important;
	border-bottom-right-radius: 10px !important;
	border-top: none !important;
	text-align: center;
	background: #f7f7f7;
}

.panel.price {
	-moz-transition: all .3s ease;
	-o-transition: all .3s ease;
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
	>.panel-heading {
		-moz-transition: all .3s ease;
		-o-transition: all .3s ease;
		-webkit-transition: all .3s ease;
		transition: all .3s ease;
	}
	h3 {
		margin-bottom: 0;
		padding: 20px 0;
	}
}

.panel-heading {
	padding: 10px 15px;
	background: $white;
}

.panel.price {
	>.panel-heading {
		padding: 0;
		position: relative;
	}
	.list-group-item {
		&:last-child {
			border-bottom-right-radius: 0px;
			border-bottom-left-radius: 0px;
		}
		&:first-child {
			border-top-right-radius: 0px;
			border-top-left-radius: 0px;
		}
	}
	margin-bottom: 1.5rem;
}

.panel-body {
	padding: 15px;
	.lead {
		strong {
			font-size: 40px;
			margin-bottom: 0;
			font-weight: 400;
		}
		font-size: 20px;
		margin-bottom: 0;
		padding: 10px 0;
	}
}

.panel-footer {
	padding: 10px 15px;
	background-color: #f7f7f7;
	border-top: 1px solid $border;
	border-bottom-right-radius: 3px;
	border-bottom-left-radius: 3px;
}

.panel.price .btn {
	box-shadow: 0 -1px 0px rgba(50, 50, 50, 0.2) inset;
	border: 0px;
}

.panel-group .panel {
	border-radius: 0;
	box-shadow: none;
	border-color: #e8ebf3;
}

.panel-default>.panel-heading {
	padding: 0;
	border-radius: 0;
	background-color: rgba(5, 117, 230, 0.1);
	border-color: #e8ebf3;
}

.panel-title {
	font-size: 14px;
	margin-bottom: 0;
	>a {
		display: block;
		padding: 15px;
		text-decoration: none;
	}
}

.more-less {
	float: right;
	color: #212121;
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
	border: 1px solid $border;
}

a {
	&:focus,
	&:hover,
	&:active {
		outline: 0;
		text-decoration: none;
	}
}

.panel1 {
	border-width: 0 0 1px 0;
	border-style: solid;
	border-color: $white;
	background: none;
	box-shadow: none;
	&:last-child {
		border-bottom: none;
	}
}

.panel-group1 {
	>.panel1:first-child .panel-heading1 {
		border-radius: 4px 4px 0 0;
	}
	.panel1 {
		border-radius: 0;
	}
}

.panel-body1 {
	padding: 10px;
}

.panel-title1 {
	font-size: 14px;
	margin-bottom: 0;
}

.panel-group1 .panel1+.panel1 {
	margin-top: 0;
}

.panel-heading1 {
	background-color: $white;
	border-radius: 0;
	border: none;
	color: $color;
	padding: 0;
}

.panel-group1 .panel-body {
	border-top: 1px solid $border;
}

.panel-title1 a {
	display: block;
	padding: 15px;
	position: relative;
	font-size: 16px;
	font-weight: 400;
	&.collapsed {
		color: $color;
	}
}

.panel-body1 {
	background: $white;
}

.panel1:last-child {
	.panel-body1 {
		border-radius: 0 0 4px 4px;
	}
	.panel-heading1 {
		border-radius: 0 0 4px 4px;
		transition: border-radius 0.3s linear 0.2s;
		&.active {
			border-radius: 0;
			transition: border-radius linear 0s;
		}
	}
}


/* #bs-collapse icon scale option */

.panel-title a.accordion-toggle {
	&:before {
		content: "\f068";
		padding: 0 10px 0 0;
		color: $white;
		font-family: FontAwesome;
		float: right;
	}
	&.collapsed:before {
		content: "\f067";
		padding: 0 10px 0 0;
		color: $white;
		font-family: FontAwesome;
		float: right;
	}
}

.panel-heading1 {
	a {
		&.collapsed:before {
			content: "\e9af";
			position: absolute;
			font-family: 'feather' !important;
			right: 10px;
			top: 12px;
			font-size: 20px;
			transition: all 0.5s;
			transform: scale(1);
		}
		&:before {
			content: "\e994";
			position: absolute;
			font-family: 'feather' !important;
			right: 10px;
			top: 12px;
			font-size: 20px;
			transition: all 0.5s;
			transform: scale(1);
		}
	}
	&.active a:before {
		content: ' ';
		transition: all 0.5s;
		transform: scale(0);
	}
}

.demo-accordion p:last-child,
.panel-group1 p:last-child {
	margin-bottom: 0;
}

#bs-collapse .panel-heading1 {
	a:after {
		content: "\f01a";
		font-size: 24px;
		position: absolute;
		font-family: 'FontAwesome';
		right: 5px;
		top: 10px;
		transform: scale(0);
		transition: all 0.5s;
	}
	&.active a:after {
		content: "\f01a";
		transform: scale(1);
		transition: all 0.5s;
	}
}

#accordion .panel-heading1 {
	a:before {
		content: "\f01a";
		font-size: 24px;
		position: absolute;
		font-family: 'FontAwesome';
		right: 5px;
		top: 10px;
		transform: rotate(180deg);
		transition: all 0.5s;
	}
	&.active a:before {
		transform: rotate(0deg);
		transition: all 0.5s;
	}
}


/*-------Accordion-----------*/


/*--------Panel----------*/

.expanel-default {
	border: $border !important;
}

.expanel-secondary {
	border: $secondary !important;
}

.expanel-success {
	border: $success !important;
}

.expanel-danger {
	border: $danger !important;
}

.expanel-secondary>.expanel-heading {
	color: $white !important;
	background-color: $secondary !important;
	border-color: $secondary !important;
}

.expanel-success>.expanel-heading {
	color: $white !important;
	background-color: $success !important;
	border-color: $success !important;
}

.expanel-danger>.expanel-heading {
	color: $white !important;
	background-color: $danger !important;
	border-color: $danger !important;
}

.expanel-warning>.expanel-heading {
	color: $white !important;
	background-color: #ecd938 !important;
	border-color: #ecd938 !important;
}

.expanel-title {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
	font-size: 16px !important;
	color: inherit !important;
}

.expanel {
	margin-bottom: 20px !important;
	background-color: $white !important;
	border: 1px solid $border !important;
	border-radius: 4px !important;
	-webkit-box-shadow: 0 1px 1px $black-05 !important;
	box-shadow: 0 1px 1px $black-05 !important;
}

.expanel-default>.expanel-heading {
	background-color: #f9faff !important;
	border: 1px solid $border !important;
}

.expanel-heading {
	padding: 10px 15px !important;
	border-bottom: 1px solid $border;
	border-top-left-radius: 3px !important;
	border-top-right-radius: 3px !important;
}

.expanel-footer {
	padding: 10px 15px !important;
	background-color: #f9faff !important;
	border-top: 1px solid $border !important;
	border-bottom-right-radius: 3px !important;
	border-bottom-left-radius: 3px !important;
}

.expanel-body {
	padding: 15px !important;
}


/*--------Panel----------*/