/*----- Typography ------*/

.heading-inverse {
	background-color: #333;
	color: $white;
	padding: 5px;
}

.heading-primary {
	color: $white;
	padding: 5px;
}

.heading-success {
	background-color: $success;
	color: $white;
	padding: 5px;
}

.heading-info {
	background-color: $info;
	color: $white;
	padding: 5px;
}

.heading-warning {
	background-color: $yellow;
	color: $white;
	padding: 5px;
}

.heading-danger {
	background-color: $danger;
	color: $white;
	padding: 5px;
}


/*----- Typography ------*/


/*------- Alignments & values-------*/

.font-monospace {
	font-family: Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.text-justify {
	text-align: justify !important;
}

.text-nowrap {
	white-space: nowrap !important;
}

.text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.text-start {
	text-align: left !important;
}

.text-end {
	text-align: right !important;
}

.text-center {
	text-align: center !important;
}

@media (min-width: 576px) {
	.text-sm-start {
		text-align: left !important;
	}
	.text-sm-end {
		text-align: right !important;
	}
	.text-sm-center {
		text-align: center !important;
	}
}

@media (min-width: 768px) {
	.text-md-start {
		text-align: left !important;
	}
	.text-md-end {
		text-align: right !important;
	}
	.text-md-center {
		text-align: center !important;
	}
}

@media (min-width: 992px) {
	.text-lg-start {
		text-align: left !important;
	}
	.text-lg-end {
		text-align: right !important;
	}
	.text-lg-center {
		text-align: center !important;
	}
}

@media (min-width: 1280px) {
	.text-xl-start {
		text-align: left !important;
	}
	.text-xl-end {
		text-align: right !important;
	}
	.text-xl-center {
		text-align: center !important;
	}
}

.text-lowercase {
	text-transform: lowercase !important;
}

.text-uppercase {
	text-transform: uppercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.font-weight-light {
	font-weight: 300 !important;
}

.font-weight-normal {
	font-weight: 400 !important;
}

.font-weight-semibold {
	font-weight: 500 !important;
}

.font-weight-bold {
	font-weight: 700 !important;
}

.font-italic {
	font-style: italic !important;
}

.text-white {
	color: $white !important;
}

.text-secondary {
	color: $secondary !important;
}

a.text-secondary {
	&:hover,
	&:focus {
		color: $secondary !important;
	}
}

.text-success {
	color: $success !important;
}

a.text-success {
	&:hover,
	&:focus {
		color: #3adfab !important;
	}
}

.text-info {
	color: $info !important;
}

a.text-info {
	&:hover,
	&:focus {
		color: #1594ef !important;
	}
}

.text-warning {
	color: $yellow !important;
}

a.text-warning {
	&:hover,
	&:focus {
		color: #ffaa33 !important;
	}
}

.text-danger {
	color: $danger !important;
}

a.text-danger {
	&:hover,
	&:focus {
		color: #fb5b50 !important;
	}
}

.text-light {
	color: #f8f9fa !important;
}

a.text-light {
	&:hover,
	&:focus {
		color: #dae0e5 !important;
	}
}

.text-dark {
	color: #504e70 !important;
}

.text-body {
	color: #3d4e67 !important;
}

.text-muted {
	color: #797896 !important;
}

.text-black-50 {
	color: $black-5 !important;
}

.text-white-50 {
	color: $white-5 !important;
}

.text-hide {
	font: 0/0 a;
	color: transparent;
	text-shadow: none;
	background-color: transparent;
	border: 0;
}

.text-wrap {
	font-size: 14px;
	line-height: 1.66;
	> {
		 :first-child {
			margin-top: 0;
		}
		 :last-child {
			margin-bottom: 0;
		}
		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-top: 1em;
		}
	}
}

.text-inherit {
	color: inherit !important;
}

.text-default {
	color: $color !important;
}

.text-muted-dark {
	color: #5f6877 !important;
}

.text-indigo {
	color: $indigo !important;
}

.text-purple {
	color: $purple !important;
}

.text-lightpink-red {
	color: #ff7088 !important;
}

.text-lightgreen {
	color: #26eda2 !important;
}

.text-pink {
	color: $secondary !important;
}

.text-red {
	color: $danger !important;
}

.text-orange {
	color: $orange !important;
}

.text-yellow {
	color: $yellow !important;
}

.text-green {
	color: $success !important;
}

.text-teal {
	color: $teal !important;
}

.text-cyan {
	color: #17a2b8 !important;
}

.text-white {
	color: $white !important;
}

.text-gray {
	color: #858d97 !important;
}

.text-gray-dark {
	color: $dark !important;
}

.text-azure {
	color: $info !important;
}

.text-lime {
	color: $lime !important;
}

.text-end {
	text-align: right;
}


/*-- Text Typing--*/

h1.animated-text {
	font-family: monospace;
	/* Web-safe typewriter-like font */
	overflow: hidden;
	/* Ensures the content is not revealed until the animation */
	border-right: .15em solid orange;
	/* The typwriter cursor */
	white-space: nowrap;
	/* Keeps the content on a single line */
	margin: 0 auto;
	/* Gives that scrolling effect as the typing happens */
	letter-spacing: .15em;
	/* Adjust as needed */
	animation: typing 3.5s steps(30, end), blinking-cursor 0.5s step-end infinite;
}

.tracking-tight {
	letter-spacing: -0.05em !important;
}

.tracking-normal {
	letter-spacing: 0 !important;
}

.tracking-wide {
	letter-spacing: 0.05em !important;
}

.leading-none {
	line-height: 1 !important;
}

.leading-tight {
	line-height: 1.3 !important;
}

.leading-normal {
	line-height: 1.5 !important;
}

.leading-loose {
	line-height: 2 !important;
}
